import axios from 'axios';
import auth from '../config/auth';
let hostname = window.location.hostname;
const requestAPISIM = axios.create({
  baseURL: auth[hostname]['API_URL_SIMSO'],
  headers: {
    'Content-Type': 'application/json',
    // Authorization: localStorage.getItem('token_sv'),
  },
});

export const getSim = async (path, options = {}) => {
  const response = await requestAPISIM.get(path, options);
  return response.data;
};

export const postSim = async (path, options = {}) => {
  const response = await requestAPISIM.post(path, options);
  return response.data;
};
export const postUpload = async (path, options = {}) => {
  const response = await requestAPISIM.post(path, options, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
