import { refreshToken } from '../services/listSim';

export const getMultipleRandom = (arr) => {
  const randomObject = [...arr].sort(() => 0.5 - Math.random());
  return randomObject;
};
export const totalDiscount = (price, priceOld) => {
  let calcNumber;
  let priceNumber = price.replace(/[^a-zA-Z0-9 ]/g, '');
  let priceOldNumber = priceOld.replace(/[^a-zA-Z0-9 ]/g, '');
  let disCount = priceOldNumber - priceNumber;
  calcNumber = Math.round((disCount / priceOldNumber) * 100) + '%';
  calcNumber = calcNumber === 'NaN%' ? '' : calcNumber;
  return calcNumber;
};

export const numberFormat = (number) => {
  let calcNumber;
  let numberPrice = number.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(numberPrice)) {
    calcNumber = number;
  } else {
    calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(numberPrice);
  }
  return calcNumber;
};

export const numberFormatText = (number) => {
  let num = number.replace(/[^a-zA-Z0-9 ]/g, '');
  if (isNaN(num)) {
    num = number;
  } else {
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: ' Triệu' },
      { v: 1e9, s: ' Tỷ' },
      { v: 1e12, s: ' T' },
      { v: 1e15, s: ' P' },
      { v: 1e18, s: ' E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    num = (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
  return num;
};
export const numberFormatTextCanvas = (number) => {
  let num = number.replace(/[^a-zA-Z0-9 ]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: ' Tr' },
    { v: 1e9, s: ' Tỷ' },
    { v: 1e12, s: ' T' },
    { v: 1e15, s: ' P' },
    { v: 1e18, s: ' E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const numberFormatDeleteDots = (number) => {
  let numberPrice = number.toString().replace(/\./g, '');
  // console.log(number.toString().replace(/\./g, ''));
  return parseInt(numberPrice);
};
export const mapRecords = (records, headerData) => {
  return records
    .filter((record) => record.length > 0)
    .map((record) =>
      record.reduce((obj, item, index) => {
        obj[headerData[index]] = item;
        return obj;
      }, {}),
    )
    .slice(1);
};
export const getLocalStorage = (name) => {
  const local = localStorage.getItem(name);
  if (local != null) {
    return JSON.parse(decodeURIComponent(atob(local)));
  }
  return null;
};
export const setLocalStorage = (name, item) => {
  const encodeData = btoa(encodeURIComponent(JSON.stringify(item)));
  localStorage.setItem(name, encodeData);
};
export const removeLocalStorage = (name) => {
  return localStorage.removeItem(name);
};

export const checkTokenDashboad = (id = '1') => {
  const nowDate = Math.floor(Date.now() / 1000);
  const getToken = getLocalStorage('token');

  const hostname = window.location.hostname;
  let isStatus = false;
  if (getToken) {
    if (getToken.exp >= nowDate && getToken.link_domain === hostname && getToken.permission === id) {
      if (getToken.exp <= nowDate + 60 * 60 * 24 * 2 && getToken.exp >= nowDate) {
        refresh(getToken.username, hostname);
      }
      isStatus = true;
    } else {
      isStatus = false;
    }
  } else {
    isStatus = false;
  }
  return isStatus;
};
const refresh = async (username, hostname) => {
  const refresh = await refreshToken({ user: username, domain: hostname });
  setLocalStorage('token', { ...refresh['data'] });
  localStorage.setItem('token_sv', refresh['token']);
};
