import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';

import images from '../../assets/images';
import auth from '../../config/auth';
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
function NotifiSim() {
  const hostname = window.location.hostname;
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/', { replace: true });
  };
  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: '#FFDE59',
      }}
    >
      <Grid container spacing={2}>
        <Grid item sx={{ margin: 'auto' }}>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src={images.logosuccess} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} md={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item>
              <Typography color="success" variant="h6" component="h2" textAlign="center">
                <b>ĐẶT SIM THÀNH CÔNG</b> <br />
              </Typography>
              <Typography color="success" variant="overline" component="h2" textAlign="center">
                Cảm ơn quý khách đã tin tưởng <br /> và đặt mua sim tại {hostname}
              </Typography>
              <Typography color="success" variant="overline" component="h2" textAlign="center">
                <b>
                  {' '}
                  Chúng tôi sẽ kiểm tra đơn hàng <br />
                  và chủ động liên lạc lại với Quý khách!
                </b>
              </Typography>
              <Button variant="contained" color="error" startIcon={<ReplyIcon />} fullWidth onClick={handleSubmit}>
                <b>Quay lại cửa hàng</b>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NotifiSim;
