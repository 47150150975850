import { useState, createContext, useEffect } from 'react';

const ThemeContext = createContext();

function ThemeProviderSim({ children }) {
  const [textSearch, setTextSearch] = useState('');
  const [boloc, setBoLoc] = useState(false);

  const toggleDataSim = (textSearchSim) => {
    setTextSearch(textSearchSim);
  };

  const toggleBoLoc = (boloc) => {
    setBoLoc(boloc);
  };

  const value = {
    toggleDataSim,
    toggleBoLoc,
    textSearch,
    boloc,
  };
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export { ThemeContext, ThemeProviderSim };
