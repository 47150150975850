/* eslint-disable no-loop-func */
import { Box, Checkbox, Container, Grid, Stack, TextField } from '@mui/material';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { toPng } from 'html-to-image';

import { ButtonUI } from '../../component/CustomerMUI';
import classNames from 'classnames/bind';
import styles from './Photo.module.scss';
import { numberFormatText } from '../../utils/helper';
const cx = classNames.bind(styles);

const EditorImage = () => {
  const domEl = useRef(null);
  const prevScrollY = useRef();
  const [dataBase64, setDataBase64] = useState('');
  const [textSimExcel, setTextSimExcel] = useState([]);
  const [textPriceSimExcel, setTextPriceSimExcel] = useState([]);
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [arrAllSimExcel, setArrAllSimExcel] = useState({
    row: { num: 3, title_1: 'SỐ ĐIỆN THOẠI', title_2: 'GIÁ', bgr: '#ef0000', color: '#ffd536', size: 45 },
    titleHeader: '',
    colorTextSim: '#ef0000',
    fontSizeTextSim: 55,
    colorTextPrice: '#000000',
    fontSizeTextPrice: 40,
    brgTable: '#fffcae',
    tablesim: [],
  });
  useEffect(() => {
    onHandleArraySim();
  }, []);

  const onButtonClick = useCallback(() => {
    if (domEl.current === null) {
      return;
    }

    toPng(domEl.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [domEl]);

  const onHandleArraySim = useCallback(() => {
    if (domEl.current === null) {
      return;
    }

    toPng(domEl.current, { cacheBust: true })
      .then((dataUrl) => {
        setDataBase64(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataBase64]);

  const handleChangeSimExcel = (e, i) => {
    if (i === 1) {
      const text = e.target.value.split('\n');
      setTextSimExcel([...text]);
      let arrAllSim = [];
      text?.map((row, i) => {
        arrAllSim.push({ sim: row, price: textPriceSimExcel[i] });
      });
      const arrSim = { ...arrAllSimExcel, tablesim: arrAllSim };
      setArrAllSimExcel(arrSim);
      onHandleArraySim();
    }
    if (i === 2) {
      const text = e.target.value.split('\n');
      setTextPriceSimExcel(text);
      let arrAllSim = [];
      textSimExcel?.map((row, i) => {
        arrAllSim.push({ sim: row, price: text[i] });
      });
      const arrSim = { ...arrAllSimExcel, tablesim: arrAllSim };
      setArrAllSimExcel(arrSim);
      onHandleArraySim();
    }
  };

  return (
    <>
      <div style={{ height: '95vh' }}>
        <Container
          ref={prevScrollY}
          maxWidth="sm"
          sx={{
            pb: '55px',
            mt: '55px',
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{ p: 2, border: '1px solid red', borderBottom: '5px solid red', borderRadius: 2 }}
          >
            <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} justifyContent="center">
              <h2>TẠO ẢNH SIM THEO BẢNG</h2>
            </Stack>

            <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} justifyContent="flex-end">
              <ButtonUI color="warning" onClick={() => setIsTableEdit(!isTableEdit)}>
                Chỉnh sửa bảng
              </ButtonUI>
              <ButtonUI color="error" onClick={onButtonClick}>
                Download
              </ButtonUI>
            </Stack>
            {dataBase64 && <img src={dataBase64} alt="Red dot" width={'100%'} />}
            {isTableEdit && (
              <Box>
                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Cột Sim</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập cột sim...`}
                    value={arrAllSimExcel?.row?.num}
                    size="small"
                    type="number"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, num: parseInt(e.target.value) },
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Tiêu đề</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập tiêu đề sim...`}
                    value={arrAllSimExcel?.titleHeader}
                    size="small"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        titleHeader: e.target.value,
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Tiêu đề cột 1</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập tiêu đề cột 1...`}
                    value={arrAllSimExcel?.row?.title_1}
                    size="small"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, title_1: e.target.value },
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Tiêu đề cột 2</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập tiêu đề cột 2...`}
                    value={arrAllSimExcel?.row?.title_2}
                    size="small"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, title_2: e.target.value },
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <Checkbox
                    color="success"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, title_2: e.target.checked ? '' : 'GIÁ' },
                      });
                      onHandleArraySim();
                    }}
                  />
                  <label>
                    <b>ẨN CỘT GIÁ </b>
                  </label>
                </Stack>
                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Size Tiêu Đề</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập kích thước chữ...`}
                    value={arrAllSimExcel?.row?.size}
                    size="small"
                    type="number"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, size: parseInt(e.target.value) },
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Màu khung</b>
                  </label>
                  <input
                    defaultValue={arrAllSimExcel?.row?.bgr}
                    type="color"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, bgr: e.target.value },
                      });
                      onHandleArraySim();
                    }}
                  />
                  <label>
                    <b>Màu chữ</b>
                  </label>
                  <input
                    defaultValue={arrAllSimExcel?.row?.color}
                    type="color"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        row: { ...arrAllSimExcel.row, color: e.target.value },
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={2} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Màu chữ cột sim</b>
                  </label>
                  <input
                    defaultValue={arrAllSimExcel?.colorTextSim}
                    type="color"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        colorTextSim: e.target.value,
                      });
                      onHandleArraySim();
                    }}
                  />
                  <label>
                    <b>Size sim</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập kích thước chữ...`}
                    value={arrAllSimExcel?.fontSizeTextSim}
                    size="small"
                    type="number"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        fontSizeTextSim: parseInt(e.target.value),
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={2} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Màu chữ cột giá</b>
                  </label>
                  <input
                    defaultValue={arrAllSimExcel?.colorTextPrice}
                    type="color"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        colorTextPrice: e.target.value,
                      });
                      onHandleArraySim();
                    }}
                  />
                  <label>
                    <b>Size giá</b>
                  </label>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                    placeholder={`Nhập kích thước chữ...`}
                    value={arrAllSimExcel?.fontSizeTextPrice}
                    size="small"
                    type="number"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        fontSizeTextPrice: parseInt(e.target.value),
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 1 }} alignItems="center">
                  <label>
                    <b>Màu nền khung</b>
                  </label>
                  <input
                    defaultValue={arrAllSimExcel?.brgTable}
                    type="color"
                    onChange={(e) => {
                      setArrAllSimExcel({
                        ...arrAllSimExcel,
                        brgTable: e.target.value,
                      });
                      onHandleArraySim();
                    }}
                  />
                </Stack>
              </Box>
            )}
            <b>
              <b>Nhập sim và giá lưu ý xuống dòng khi nhập xong 1 sim</b>
            </b>
            <Stack direction="row" sx={{ m: 2 }}>
              <TextField
                sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                multiline
                placeholder={`Nhập sim...`}
                fullWidth
                size="small"
                onChange={(e) => handleChangeSimExcel(e, 1)}
              />
              <TextField
                sx={{ border: '1px solid #fff', borderRadius: '5px', background: '#fff' }}
                multiline
                placeholder={`Nhập giá...`}
                fullWidth
                size="small"
                onChange={(e) => handleChangeSimExcel(e, 2)}
              />
            </Stack>
          </Stack>

          <div className={cx('dis-none')}>
            <Grid
              container
              ref={domEl}
              sx={{ width: '1800px', fontSize: '45px', background: arrAllSimExcel?.brgTable }}
            >
              <Grid container justifyContent="center" sx={{ color: arrAllSimExcel?.colorTextSim }}>
                <b style={{ fontSize: arrAllSimExcel?.row?.size }}>{arrAllSimExcel?.titleHeader}</b>
              </Grid>
              <Grid container item xs={12}>
                {[...Array.from(Array(arrAllSimExcel?.row?.num || 1).keys())].map((num, i) => (
                  <Grid
                    key={i}
                    container
                    item
                    xs={12 / arrAllSimExcel?.row?.num}
                    className={cx('domEl')}
                    textAlign="center"
                    sx={{ background: arrAllSimExcel?.row?.bgr, color: arrAllSimExcel?.row?.color }}
                  >
                    {arrAllSimExcel?.row?.title_2 ? (
                      <>
                        <Grid item xs={8} sx={{ borderRight: '1px solid #000' }}>
                          <b style={{ fontSize: arrAllSimExcel?.row?.size }}>{arrAllSimExcel?.row?.title_1}</b>
                        </Grid>
                        <Grid item xs={4}>
                          <b style={{ fontSize: arrAllSimExcel?.row?.size }}>{arrAllSimExcel?.row?.title_2}</b>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sx={{ borderRight: '1px solid #000' }}>
                          <b style={{ fontSize: arrAllSimExcel?.row?.size }}>{arrAllSimExcel?.row?.title_1}</b>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
              {arrAllSimExcel?.tablesim?.map((row, index) => (
                <Grid
                  container
                  key={index}
                  item
                  xs={12 / arrAllSimExcel?.row?.num}
                  className={cx('domEl')}
                  textAlign="center"
                >
                  {arrAllSimExcel?.row?.title_2 ? (
                    <>
                      <Grid item xs={8} sx={{ borderRight: '1px solid #000' }}>
                        <b style={{ color: arrAllSimExcel?.colorTextSim, fontSize: arrAllSimExcel?.fontSizeTextSim }}>
                          {row.sim}
                        </b>
                      </Grid>
                      <Grid item xs={4}>
                        <b
                          style={{ color: arrAllSimExcel?.colorTextPrice, fontSize: arrAllSimExcel?.fontSizeTextPrice }}
                        >
                          {numberFormatText(row?.price || '')}
                        </b>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sx={{ borderRight: '1px solid #000' }}>
                        <b style={{ color: arrAllSimExcel?.colorTextSim, fontSize: arrAllSimExcel?.fontSizeTextSim }}>
                          {row.sim}
                        </b>
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditorImage;
