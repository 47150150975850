const auth = {
  localhost: {
    apiKey: 'AIzaSyB3eC8iX0K4p_TU6srfIhF8sL3dqE1phq0',
    sheetId: '1x0RZSgBySD2o6IFNxKaEEaIKhfkfO3kmzNnJBWzvTLQ',
    sheetOrderSim: 'AKfycbwFU2_C0bNKw_f9L67qoE0fOPhAwWlCrGzg6kMsrcqZknFxmWp_noeztB0EIB3ndpin',
    serviceMail: 'service_2sse52w',
    templateMail: 'template_8cdx36a',
    keyPublicMail: 'XuqrWtbwuGfdBr5d8',
    layoutSim: 2, // 2 chi tiet, 1 thu gon
    authGuard: 0, // 1 login, 0 no login
    sheetsOptions: 'Tổng Sim,menusim,taikhoan',
    logo: 'logokhoso',
    // API_URL_SIMSO: 'http://localhost:3000/api_sim',
    API_URL_SIMSO: 'https://apisv.khoso.com.vn/api_sim',
    TITLE_HOME: 'TEXT ON PHOTOS',
    TITLE_HEADER: 'THƯ VIỆN ẢNH SIM ĐẸP',
    HOTTLINE_SIM: '0984.49.49.49',
    HOTTLINE_SIM_CALL: '0984494949',
    HOTTLINE_ZALO: '0979198928',
  },
  'photo.khoso.com.vn': {
    apiKey: 'AIzaSyB3eC8iX0K4p_TU6srfIhF8sL3dqE1phq0',
    sheetId: '1x0RZSgBySD2o6IFNxKaEEaIKhfkfO3kmzNnJBWzvTLQ',
    sheetOrderSim: 'AKfycbwFU2_C0bNKw_f9L67qoE0fOPhAwWlCrGzg6kMsrcqZknFxmWp_noeztB0EIB3ndpin',
    serviceMail: 'service_2sse52w',
    templateMail: 'template_8cdx36a',
    keyPublicMail: 'XuqrWtbwuGfdBr5d8',
    layoutSim: 2,
    authGuard: 0, // 1 login, 0 no login
    sheetsOptions: 'Tổng Sim,menusim',
    logo: 'logokhoso',
    BASE_URL: 'https://photor.vn/#',
    API_URL_SIMSO: 'https://apisv.khoso.com.vn/api_sim',
    TITLE_HOME: 'TEXT ON PHOTOS',
    TITLE_HEADER: 'THƯ VIỆN ẢNH SIM ĐẸP',
    HOTTLINE_SIM: '0984.49.49.49',
    HOTTLINE_SIM_CALL: '0984494949',
    HOTTLINE_ZALO: '0979198928',
  },
};
export default auth;
