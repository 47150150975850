import { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import IconButton from '@mui/material/IconButton';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import images from '../../../assets/images';
import Search from './Search';
import config from '../../../config';
import styles from './Header.module.scss';
import auth from '../../../config/auth';
import { Stack } from '@mui/material';
const cx = classNames.bind(styles);

function Header() {
  const [actionBoLoc, setActionaction] = useState(false);
  const contextBoLoc = useContext(ThemeContext);
  const hostname = window.location.hostname;

  const handleBoLoc = () => {
    // setActionLamp(!actionLamp);
    contextBoLoc.toggleBoLoc(true);
  };

  return (
    <header className={cx('wrapper')}>
      <div className={cx('inner')}>
        <Grid container>
          <Grid item xs={10} className={cx('logo-center')}>
            {/* <img src={images[auth[hostname]['logo']]} alt="Khosim8" className={cx('logo')} /> */}
            <ImageSearchIcon style={{ color: '#fff' }} />
            <label className={cx('color-trang')}>
              <b>{auth[hostname]['TITLE_HEADER']}</b>
            </label>
          </Grid>

          {/* <Grid item xs={2} className={cx('logo-center')} justifyContent="flex-end">
            <IconButton aria-label="light" size="large" sx={{ pl: '25px', pb: '7px', pt: '4px' }} onClick={handleBoLoc}>
              <WallpaperIcon fontSize="inherit" style={{ color: 'rgb(255 223 227)' }} />
            </IconButton>
          </Grid> */}

          {/* <Grid item md={11.5} xs={10.5}>
            <Search />
            <span className={cx('title-note-sim')}>
              Hot key: <b>09*, 08*, 07*, 0909*, 39, 79, 86</b>
            </span>
          </Grid>
          <Grid item md={0.5} xs={1.5}>
            <IconButton aria-label="light" size="large" sx={{ pl: '25px', pb: '7px', pt: '4px' }} onClick={handleBoLoc}>
              <FilterAltRoundedIcon fontSize="inherit" style={{ color: 'rgb(255 223 227)' }} />
            </IconButton>
          </Grid> */}
        </Grid>
      </div>
    </header>
  );
}
export default Header;
