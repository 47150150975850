import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useGoogleSheets from 'use-google-sheets';
import { Backdrop, Button, ButtonBase, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

import images from '../../assets/images';
import auth from '../../config/auth';
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
function Login() {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [errorLogin, setErrorLogin] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(true);

  const navigate = useNavigate();
  const hostname = window.location.hostname;

  const { data, loading, error } = useGoogleSheets({
    apiKey: auth[hostname]['apiKey'],
    sheetId: auth[hostname]['sheetId'],
    sheetsOptions: [{ id: 'taikhoan' }],
  });

  const handleSubmit = () => {
    setLoadingLogin(true);
    const userName = data[0]['data'][0]['user'];
    const passWord = data[0]['data'][0]['pass'];
    if (userName === user && passWord === pass) {
      setLoadingLogin(false);
      localStorage.setItem('items', JSON.stringify(data[0]['data'][0]));
      setErrorLogin('');
      setUser('');
      setPass('');
      navigate('/', { replace: true });
    } else {
      setErrorLogin('Sai tài khoản hoặc mật khẩu đăng nhập');
      setLoadingLogin(false);
    }
  };

  useEffect(() => {
    document.title = 'Đăng Nhập';
    const items = JSON.parse(localStorage.getItem('items'));
    if (items && data.length > 0) {
      const userName = data[0]['data'][0]['user'];
      const passWord = data[0]['data'][0]['pass'];
      if (userName === items.user && passWord === items.pass) {
        setLoadingLogin(false);
        localStorage.setItem('items', JSON.stringify(data[0]['data'][0]));
        setErrorLogin('');
        setUser('');
        setPass('');
        navigate('/', { replace: true });
      } else {
        setLoadingLogin(false);
      }
    } else {
      setLoadingLogin(false);
    }
  }, [data]);
  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: '#FFF',
      }}
    >
      <Grid container spacing={2}>
        <Grid item sx={{ margin: 'auto' }}>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src={images.logologin} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} md={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                value={user}
                error
                onChange={(e) => setUser(e.target.value)}
                label="Tài khoản"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                value={pass}
                error
                onChange={(e) => setPass(e.target.value)}
                type="password"
                label="Mật khẩu"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item>
              <Typography color="error" variant="h6" component="h2">
                {errorLogin}
              </Typography>
              <Button
                variant="contained"
                color="error"
                endIcon={<LoginIcon />}
                fullWidth
                disabled={loading}
                onClick={handleSubmit}
              >
                <b>Đăng Nhập</b>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingLogin}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Paper>
  );
}

export default Login;
