import { useEffect, useState, useRef, useContext } from 'react';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import { ThemeContext } from '../../../../component/ThemeContext/ThemeContext';
import styles from './Search.module.scss';
import { useDebounce } from '../../../../hooks';

const cx = classNames.bind(styles);

function Search() {
  const location = useLocation();
  const history = useNavigate();
  // const pathname = location.pathname;
  const [searchValue, setSearchValue] = useState('');
  // const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const contextSearch = useContext(ThemeContext);
  // const debounced = useDebounce(searchValue, 1000);
  let textSearch = contextSearch.textSearch;

  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const inputRef = useRef();

  // useEffect(() => {
  //   if (!debounced.trim()) {
  //     setSearchResult([]);
  //     return;
  //   }
  //   setLoading(true);
  //   contextSearch.toggleDataSim(debounced);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, [debounced]);

  useEffect(() => {
    setSearchValue(textSearch);
  }, [textSearch]);

  const handleClear = () => {
    setSearchValue('');
    // setSearchResult([]);
    contextSearch.toggleDataSim('');
    inputRef.current.focus();
    setOpen(false);
  };

  const handleChange = (e) => {
    if (location.pathname !== '/') {
      history('/');
    }
    const searchValue = e.target.value;
    if (!searchValue.startsWith(' ')) {
      setSearchValue(searchValue);
    }
  };

  const handleEnterKey = (e) => {
    // console.log(123);
    const searchValue = e.target.value;
    if (!searchValue.startsWith(' ')) {
      if (e.key === 'Enter') {
        contextSearch.toggleDataSim(searchValue);
        setOpen(false);
      }
    }
  };

  const handleSubmit = () => {
    contextSearch.toggleDataSim(searchValue);
  };

  const handleClick = (event) => {
    setOpen(Boolean(anchorEl ? null : event.currentTarget));
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const id = open ? 'simple-popper' : undefined;
  return (
    <div className={cx('search')} onClick={handleClick}>
      <input
        ref={inputRef}
        value={searchValue}
        placeholder="Tìm kiếm nội dung trên phoi.vn" //Tìm sim (Ví dụ: 3979, 0909*, 09*79)
        spellCheck={false}
        onChange={handleChange}
        onKeyPress={handleEnterKey}
      />
      {!!searchValue && !loading && (
        <button className={cx('clear')} onClick={handleClear}>
          <CancelIcon sx={{ fontSize: 16 }} />
        </button>
      )}
      {!!searchValue && !loading && <button className={cx('clear')} onClick={handleClear}></button>}
      {loading && <CachedIcon sx={{ fontSize: 16 }} className={cx('loading')} />}
      <button className={cx('search-btn')} onMouseDown={(e) => e.preventDefault()} onClick={handleSubmit}>
        <SearchIcon sx={{ fontSize: 30 }} />
      </button>
    </div>
  );
}

export default Search;
