const images = {
  logo: require('./logo8.png'),
  logoxs: require('./logo8.png'),
  logologin: require('./logologin.png'),
  logokhoso: require('./logokhoso.png'),
  logosuccess: require('./logosuccess.png'),
  iconZalo: require('./icon-zalo.png'),
  logo_mb: require('./logo-mobifone.svg').default,
  logo_vt: require('./logo-viettel.svg').default,
  logo_vn: require('./logo-vinaphone.svg').default,
  logo_vnmb: require('./logo-vnmb.svg').default,
  logo_itel: require('./itelecom_logo.png'),
  phoi_viettel: require('./phoi-viettel.png'),
  phoi_vina: require('./phoi-vina.png'),
  phoi_mobi: require('./phoi-mobi.png'),
  phoi_vnmb: require('./phoi-vnmb.png'),
  phoi_itel: require('./phoi-itel.png'),
  phoi_1_sim: require('./phoi-1-sim.jpg'),
  phoi_2_sim_mobi: require('./phoi-2-sim-mobifone.jpg'),
  phoi_anh_1: require('./phoi-anh-1.jpg'),
  phoi_anh_2: require('./phoi-anh-2.jpg'),
};

export default images;
