import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Paper from '@mui/material/Paper';

function Footer() {
  const pathname = window.location.pathname;
  const [value, setValue] = useState(pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}
          sx={{
            '& .Mui-selected, .Mui-selected > svg': {
              color: '#d70018',
              fontWeight: '700',
            },
          }}
        >
          <BottomNavigationAction
            value={'/'}
            label="Trang Chủ"
            icon={<StorefrontOutlinedIcon />}
            component={Link}
            to={'/'}
          />
        </BottomNavigation>
      </Paper> */}
    </Box>
  );
}

export default Footer;
