import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { styled } from '@mui/material/styles';
import { AppBar, Grid, IconButton, Stack, Toolbar, Fab, Box, Tooltip, Drawer, Container } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreIcon from '@mui/icons-material/MoreVert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import TuneIcon from '@mui/icons-material/Tune';
import BrushIcon from '@mui/icons-material/Brush';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import InterestsIcon from '@mui/icons-material/Interests';
import FlipIcon from '@mui/icons-material/Flip';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

import styles from './Photo.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { ButtonUI } from '../../component/CustomerMUI';
import images from '../../assets/images';
import { TEXT } from '../../utils/defaultShapes';
const cx = classNames.bind(styles);

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

function Photo() {
  let imageRef;
  const ext = 'jpeg';
  const fileRef = useRef(null);
  const STROKE = '#000000';
  const FILL = 'rgba(255, 255, 255, 0.0)';
  const { editor, onReady } = useFabricJSEditor();
  const [textBox, setTextBox] = useState([]);
  const [sizeImage, setSizeImage] = useState({});
  const [toolBar, setToolBar] = useState({ isToolBar: false, x: 142.66666666666663, y: 390.6666666666666 });
  const [previewImage, setPreviewImage] = useState('');
  const [previewImageIsLoading, setpreviewImageIsLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getFabricImage = (image) =>
    new Promise((res, rej) => {
      fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
        res(img);
      });
    });

  const onUploadImage = async (e) => {
    setpreviewImageIsLoading(true);
    const image = e.target.files[0];
    let fabricImage = await getFabricImage(image);
    imageRef = fabricImage;
    previewImageAction();
    // I need it to await and take action on imageRef here
    setpreviewImageIsLoading(false);
  };
  const previewImageAction = async () => {
    if (imageRef.width > 256) {
      imageRef.scaleToWidth(imageRef.width);
    }
    const base64 = imageRef.toDataURL({
      format: ext,
      enableRetinaScaling: false,
    });
    setPreviewImage(base64);
    addImage(base64);
    const newFile = await dataUrlToFile(base64, 'tempfile.jpeg');
    return base64;
  };
  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpeg' });
  }

  // handle text
  const onAddText = () => {
    const object = new fabric.Textbox(TEXT['text'], { ...TEXT['option'] });
    setTextBox([...textBox, TEXT]);
    // editor?.canvas.setActiveObject(object);
    editor?.canvas.add(object);
    editor?.canvas.renderAll();
  };

  const selectAllCanvasObjects = () => {
    const objs = editor?.canvas.getObjects().filter((o) => {
      if (o.get('type') === 'text') {
        return o.set('active', true);
      }
    });
    console.log(objs);
  };
  const onAddRectangle = () => {
    editor.addRectangle();
  };
  const onDelete = () => {
    editor.deleteSelected();
  };
  const onDeleteAll = () => {
    editor.deleteAll();
  };
  const onZoomIn = () => {
    editor.zoomIn();
  };
  const onZoomOut = () => {
    editor.zoomOut();
  };
  const resizeCanvas = () => {
    editor?.canvas.setWidth(sizeImage.width);
    editor?.canvas.setHeight(sizeImage.height);
    editor?.canvas.renderAll();
    editor?.canvas.calcOffset();
  };

  useEffect(() => {
    resizeCanvas();
    editor?.canvas.on('mouse:move', function (e) {
      // getMouseCoords(e);
      const isToolBarText = editor?.canvas?.getActiveObject()?.isMoving;
      setToolBar({ ...toolBar, isToolBar: isToolBarText });
    });
    editor?.canvas.on('mouse:up', function (e) {
      getMouseCoords(e);
    });
  }, [fabric, editor]);

  const addImage = (url) => {
    fabric.Image.fromURL(
      url,
      function (oImg) {
        setSizeImage({ width: oImg.width, height: oImg.height });
        oImg.selectable = false;
        oImg.evented = false;
        editor?.canvas.add(oImg);
        editor?.canvas.sendToBack(oImg);
        editor?.canvas.renderAll();
      },
      { crossOrigin: 'anonymous' },
    );
  };
  const onAddImage = () => {
    addImage(images.phoi_1_sim);
  };
  const handleDownload = () => {
    const url = editor?.canvas.toDataURL();
    var link = document.createElement('a');
    link.download = 'filename.png';
    link.href = url;
    link.click();
  };

  const toggleDrawer = (open) => {
    setIsOpenDrawer({ ...isOpenDrawer, isOpenDrawer: open });
  };

  const handleColorText = (e, index) => {
    const updateArrText = textBox.map((obj, i) => {
      if (i === index) {
        return { ...obj, option: { fill: e.target.value } };
      }
      return obj;
    });
    // console.log(updateArrText);
    setTextBox(updateArrText);
    editor?.canvas?.getActiveObject().set('fill', e.target.value);
    editor?.canvas.renderAll();
  };
  const getMouseCoords = (event) => {
    const pointer = editor?.canvas?.getActiveObject();
    const posX = pointer?.left;
    const posY = pointer?.top;
    const objToolBar = {
      isToolBar: editor?.canvas?.getActiveObject()?.isMoving,
      x: posX,
      y: posY,
    };
    setToolBar(objToolBar);
  };

  return (
    <Container sx={{ p: 0 }}>
      <div className={cx('main-content')}>
        {/* {!toolBar.isToolBar && editor?.canvas?.getActiveObject() && (
          <div
            style={{
              position: 'absolute',
              left: toolBar.x,
              top: toolBar.y,
              display: 'block',
              zIndex: '10',
              background: 'rgb(48 48 48 / 76%)',
              borderRadius: 5,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }}>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Text
                </ButtonUI>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Font
                </ButtonUI>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Color
                </ButtonUI>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Size
                </ButtonUI>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Xoay
                </ButtonUI>
                <ButtonUI onClick={onAddText} borderBtn="#ffffff" colorText="#fff" bgrColorBtn="#000">
                  Move
                </ButtonUI>
              </Stack>
            </Box>
          </div>
        )} */}

        <Fragment>
          <Drawer
            anchor={'bottom'}
            open={isOpenDrawer.isOpenDrawer}
            onClose={() => toggleDrawer(false)}
            sx={{
              '& .MuiPaper-root': {
                borderTopLeftRadius: '10px',
              },
            }}
          >
            {isOpenDrawer.action === 'TextFields' && (
              <Box sx={{ width: 250, p: 1, height: 500 }}>
                <label className={cx('title-sort-sim')}>
                  <b>VĂN BẢN</b>
                </label>
                <Stack direction="column" spacing={0.5}>
                  <ButtonUI onClick={onAddText} color="error">
                    Thêm văn bản
                  </ButtonUI>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  {textBox.map((text, index) => (
                    <input
                      type="color"
                      key={index}
                      value={text['option']['fill']}
                      size="10"
                      onChange={(e) => handleColorText(e, index)}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Drawer>
        </Fragment>
        <Grid container spacing={0.5} sx={{ ml: '5px' }}>
          <Grid item xs={12}>
            <div className={cx('download-loading')}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {/* <ButtonUI onClick={onAddText}>Add Text</ButtonUI> */}
                {/* <ButtonUI onClick={onAddImage}>Add ảnh</ButtonUI> */}
                {/* <ButtonUI onClick={onAddCircle}>Add Text</ButtonUI>
            <ButtonUI onClick={onAddRectangle}>Add Rectangle</ButtonUI> */}
                <ButtonUI onClick={onZoomIn}>Zoom In</ButtonUI>
                <ButtonUI onClick={onZoomOut}>Zoom Out</ButtonUI>
                <ButtonUI onClick={onDelete}>
                  <DeleteIcon />
                </ButtonUI>
                <ButtonUI onClick={onDeleteAll}>
                  <DeleteSweepIcon />
                </ButtonUI>
                {/* <ButtonUI onClick={onZoomIn}>zoomIn</ButtonUI>
            <ButtonUI onClick={onZoomOut}>zoomOut</ButtonUI> */}
                <ButtonUI onClick={handleDownload}>
                  <CloudDownloadIcon />
                </ButtonUI>
                <ButtonUI variant="contained" component="label">
                  <input
                    type="file"
                    ref={fileRef}
                    onChange={onUploadImage}
                    disabled={previewImageIsLoading}
                    accept="image/*"
                    hidden
                  />
                  <CloudUploadIcon />
                </ButtonUI>
              </Stack>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <FabricJSCanvas onReady={onReady} className={cx('sample-canvas')} />
          </Grid>
        </Grid>

        <AppBar position="fixed" color="error" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'Rotate' })}
            >
              <RotateLeftIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'Shape' })}>
              <InterestsIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'Flip' })}>
              <FlipIcon />
            </IconButton>
            <StyledFab
              color="warning"
              aria-label="add"
              onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'ImageSearch' })}
            >
              <ImageSearchIcon />
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="inherit" onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'TextFields' })}>
              <TextFieldsIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'Draw' })}>
              <BrushIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => setIsOpenDrawer({ isOpenDrawer: true, action: 'Filter' })}>
              <TuneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </Container>
  );
}

export default Photo;
