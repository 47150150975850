/* eslint-disable no-loop-func */
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Slider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import React, { useState, useRef, useEffect, Fragment, useContext, useCallback } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { styled, useTheme } from '@mui/material/styles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ReplyIcon from '@mui/icons-material/Reply';
import Masonry from '@mui/lab/Masonry';

import images from '../../assets/images';
import { ButtonUI } from '../../component/CustomerMUI';
import classNames from 'classnames/bind';
import styles from './Photo.module.scss';
import photo from '../../utils/albumsPhoto';
import { ThemeContext } from '../../component/ThemeContext/ThemeContext';
import { getLocalStorage, numberFormatText, setLocalStorage } from '../../utils/helper';
import { red } from '@mui/material/colors';
import {
  addImageSamples,
  getAllImageSample,
  getAllImageSampleCount,
  removeImageSamples,
  updateImageSample,
  uploadImageSample,
} from '../../services/listSim';
import auth from '../../config/auth';

const cx = classNames.bind(styles);
const soluongTextPhoi = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
];
const loaiAnh = [
  { title: 'Ảnh Đẹp', link: 'anh-dep' },
  { title: 'Ảnh Viettel', link: 'viettel' },
  { title: 'Ảnh Mobifone', link: 'mobifone' },
  { title: 'Ảnh Vinaphone', link: 'vinaphone' },
];
const loaiAnhPhoi = [
  { title: 'Phôi Viettel', link: 'phoi-viettel' },
  { title: 'Phôi Mobifone', link: 'phoi-mobifone' },
  { title: 'Phôi Vinaphone', link: 'phoi-vinaphone' },
];
const zip = new JSZip();
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #e10000',
  boxShadow: 24,
  borderRadius: 2,
  p: 1,
  height: '90vh',
  overflow: 'auto',
};
const styleDetail = {
  top: '10%',
  // position: 'absolute',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #e10000',
  boxShadow: 24,
  borderRadius: 2,
  p: 1,
};
const MuiBottomNavigationAction = styled(BottomNavigationAction)(`
  color: black;
  &.Mui-selected {
    color: red;
  };
  min-width: auto;
`);
const EditorImage = () => {
  let hostname = window.location.hostname;
  let inputArr = {
    value: [],
    valuePrice: [],
  };
  let mauText = {
    value: '',
    option: {
      size: 80,
      top: 50,
      left: 100,
      color: 'black',
      border: false,
      borderColor: '#ebd700',
      bold: '',
      italic: '',
      underline: false,
      font: 'Arial',
      rotate: 0,
      isFillRect: false,
      fillRectStyle: '#0000006b',
      strokeTextLine: 5,
      isprice: false,
    },
  };
  let mauTextPrice = {
    value: '',
    option: {
      size: 80,
      top: 50,
      left: 50,
      color: 'black',
      border: false,
      borderColor: '#ebd700',
      bold: '',
      italic: '',
      underline: false,
      font: 'Arial',
      rotate: 0,
      isFillRect: false,
      fillRectStyle: '#0000006b',
      strokeTextLine: 5,
      isprice: true,
    },
  };
  const canvasRef = useRef(null);
  const domEl = useRef(null);
  const uploadRef = useRef();
  const prevScrollY = useRef();
  const [actionEditor, setActionEditor] = useState({ action: '', toolbar: 0, id: 0 });
  const [pos, setPos] = useState({ top: '', left: '', width: '', height: '' });
  const [arr, setArr] = useState([]);
  const [arrOption, setArrOption] = useState(mauText);
  const [nameImageSV, setNameImageSV] = useState('');
  const [messNotifi, setMessNotifi] = useState({ isstt: false, notifi: '' });
  const [text, setText] = useState(inputArr);
  const [selectedText, setSelectedText] = useState(-1);
  const [openSpeedDial, setOpenSpeedDial] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState({ isOpenDrawer: false, action: '' });
  const [image, setImage] = useState(new Image());
  const [selectedImage, setSelectedImage] = useState();
  const [imageSample, setImageSample] = useState([]);
  const [arrMauText, setArrMauText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState(1);
  const contextSearch = useContext(ThemeContext);
  let isAlbum = contextSearch.boloc;
  const changeTimer = useRef(null);
  const fontCanvas = [
    'Comic Sans MS',
    'VCENTB',
    'Abril',
    'Arial',
    'Arendon',
    'Georgia',
    'Tahoma',
    'Messi',
    'Helvetica',
    'LeagueVaria',
    'Times New Roman',
    'sofiapro-semibold',
    'fantasys',
    'sans-serif',
    'monospace',
    'UTM-Avo',
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = useState(false);
  const [amountOfText, setAmountOfText] = useState(0);
  const [anetwork, setAnetwork] = useState('anh-dep');
  const [amountOfPrice, setAmountOfPrice] = useState(0);
  const [openAddPrice, setOpenAddPrice] = useState(false);
  const [arrCanvasImage, setArrCanvasImage] = useState([]);
  const [arrCanvasImageDetail, setArrCanvasImageDetail] = useState([]);
  const [arrTextVBs, setArrTextVBs] = useState({});
  const [authToken, setAuthToken] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startX, setStartX] = useState({ start: 0, point: 0 });
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const sv = urlParams.get('sv');
  const fb = urlParams.get('fb');
  const getArrValueSim = urlParams.get('sim');
  const getArrValuePrice = urlParams.get('price');

  useEffect(() => {
    const decodedToken = jwtDecode(fb);

    if (decodedToken) {
      // console.log(decodedToken);s
      setAuthToken(decodedToken);
    } else {
      setAuthToken({});
    }
  }, [fb]);

  useEffect(() => {
    if (getArrValueSim && layout === 3) {
      const arrResult = {
        value: getArrValueSim?.split(','),
        valuePrice: getArrValuePrice ? getArrValuePrice?.split(',') : [],
      };
      setArrTextVBs(arrResult);

      canvasDetail(imageSample, arrResult);
    }
  }, [layout]);

  const resetArr = async () => {
    setAmountOfText(1);
    setAmountOfPrice(0);
    setOpenAddPrice(0);
    setArrTextVBs([]);
    setArrMauText([]);
    setArr([]);
    setText(inputArr);
    setOpen(false);
    setIsOpenDrawer({ ...isOpenDrawer, isOpenDrawer: false });
    setAnetwork('anh-dep');
  };

  useEffect(() => {
    if (sv === '1') {
      setLocalStorage('sv', sv);
    }
  }, []);
  useEffect(() => {
    if (canvasRef.current) {
      const drawImage = () => {
        const base_image = new Image();
        base_image.setAttribute('crossorigin', 'anonymous');
        base_image.src = images.phoi_anh_2;
        base_image.onload = () => {
          setImage(base_image);
        };
      };
      drawImage();
    }
  }, [canvasRef]);

  useEffect(() => {
    drawTextNew();
  }, [image, arr, actionEditor]);

  const drawTextNew = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas?.getContext('2d');
      ctx.canvas.width = image.width;
      ctx.canvas.height = image.height;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // ctx.fillText(text, style?.left, style?.top);
      arr?.map((text) => {
        canvasTextAutoLine(
          text.value,
          canvas,
          text.option?.left,
          text.option?.top,
          text.option?.size,
          text.option?.color,
          text.option?.border,
          text.option?.borderColor,
          text.option?.bold,
          text.option?.italic,
          text.option?.underline,
          text.option?.font,
          text.option?.rotate,
          text.option?.isFillRect,
          text.option?.fillRectStyle,
          text.option?.strokeTextLine,
        );
      });
    }
  };

  // click boloc tư header
  useEffect(() => {
    if (isAlbum) {
      setIsOpenDrawer({ isOpenDrawer: true, action: 'Albums' });
    }
  }, [isAlbum]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  const updateStyle = (e, key, index) => {
    // setStyle((prev) => ({ ...prev, [key]: parseInt(e.target.value, 10) }));

    const updateArrStyle = arr.map((obj, i) => {
      if (i === index) {
        return { ...obj, option: { ...obj.option, [key]: parseInt(e.target.value, 10) } };
      }
      return obj;
    });
    // console.log(updateArrStyle);
    setArr(updateArrStyle);
  };

  const handleUploadFile = (e) => {
    if (e.target.files) {
      // setArr([]);
      const imageFile = e.target.files[0]; //here we get the image file
      setSelectedImage(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = (e) => {
        const myImage = new Image(); // Creates image object
        myImage.src = e.target.result;
        myImage.onload = () => {
          setImage(myImage);
        };
      };
    }
  };

  const handleColorText = (e, index, key) => {
    const updateArrStyle = arr.map((obj, i) => {
      if (i === index) {
        return { ...obj, option: { ...obj.option, [key]: e.target.value } };
      }
      return obj;
    });
    // console.log(updateArrStyle);
    setArr(updateArrStyle);
  };

  const handleBorderChecker = (e, index, key) => {
    const updateArrStyle = arr.map((obj, i) => {
      if (i === index) {
        return { ...obj, option: { ...obj.option, [key]: e.target.checked } };
      }
      return obj;
    });
    // console.log(updateArrStyle);
    setArr(updateArrStyle);
  };

  const handleUpdateText = (key, value, index) => {
    const updateArrStyle = arr.map((obj, i) => {
      if (i === index) {
        return { ...obj, option: { ...obj.option, [key]: value } };
      }
      return obj;
    });
    // console.log(updateArrStyle);
    setArr(updateArrStyle);
  };

  const handleFontText = (key = '', value = '') => {
    const option = { ...arrOption, option: { ...arrOption.option, [key]: value } };
    setArrOption(option);
  };

  const download = () => {
    const img = zip.folder('images');
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    ctx.canvas.width = image.width;
    ctx.canvas.height = image.height;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // ctx.fillText(text, style?.left, style?.top);
    if (arrMauText[0]?.value.length > parseInt(amountOfText)) {
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });

      const iterationsSim = Math.ceil(arrMauText[0]?.value?.length / amountOfText);
      const iterationsPrice = Math.ceil(arrMauText[0]?.valuePrice?.length / amountOfPrice);
      for (let i = 0; i < iterationsSim; i++) {
        const startIndex = i * amountOfText;
        const endIndex = (i + 1) * amountOfText;
        let batch = arrMauText[0]?.value?.slice(startIndex, endIndex);
        let batchPrice;

        if (arrPrice.length > 0) {
          for (let j = 0; j < iterationsPrice; j++) {
            const startIndex = i * amountOfPrice;
            const endIndex = (i + 1) * amountOfPrice;
            batchPrice =
              arrMauText[0]?.valuePrice?.slice(startIndex, endIndex).length > 0
                ? arrMauText[0]?.valuePrice?.slice(startIndex, endIndex)
                : arrMauText[0]?.valuePrice?.slice(0, 1);
          }
        }

        arrSim.forEach((item, index) => {
          item.value = batch[index];
        });
        if (batchPrice?.length > 0) {
          // console.log(arrPrice, iterationsPrice, batchPrice);
          arrPrice?.forEach((item, index) => {
            item.value = batchPrice[index];
          });
        } else {
          arrPrice = [];
        }
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        arrSim.map((text) => {
          canvasTextAutoLine(
            text.value,
            canvas,
            text.option?.left,
            text.option?.top,
            text.option?.size,
            text.option?.color,
            text.option?.border,
            text.option?.borderColor,
            text.option?.bold,
            text.option?.italic,
            text.option?.underline,
            text.option?.font,
            text.option?.rotate,
            text.option?.isFillRect,
            text.option?.fillRectStyle,
            text.option?.strokeTextLine,
          );
        });
        arrPrice.map((text) => {
          canvasTextAutoLine(
            text.value,
            canvas,
            text.option?.left,
            text.option?.top,
            text.option?.size,
            text.option?.color,
            text.option?.border,
            text.option?.borderColor,
            text.option?.bold,
            text.option?.italic,
            text.option?.underline,
            text.option?.font,
            text.option?.rotate,
            text.option?.isFillRect,
            text.option?.fillRectStyle,
            text.option?.strokeTextLine,
          );
        });
        const savable = new Image();
        savable.src = canvas.toDataURL();
        img.file(`${i}.png`, savable.src.substring(savable.src.indexOf(',') + 1), { base64: true });
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        // see FileSaver.js
        saveAs(content, 'images.zip');
      });
    } else if (arrMauText[0]?.value?.length <= parseInt(amountOfText)) {
      // arr[0].value.map((valtext, i) => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      arr.map((text, i) => {
        canvasTextAutoLine(
          text.value,
          canvas,
          text.option?.left,
          text.option?.top,
          text.option?.size,
          text.option?.color,
          text.option?.border,
          text.option?.borderColor,
          text.option?.bold,
          text.option?.italic,
          text.option?.underline,
          text.option?.font,
          text.option?.rotate,
          text.option?.isFillRect,
          text.option?.fillRectStyle,
          text.option?.strokeTextLine,
        );
      });
      const link = document.createElement('a');
      link.download = arr[0]['value'] + '.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
      // });
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const link = document.createElement('a');
      link.download = 'image.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    }
  };

  /*
str:chuỗi để vẽ
canvas:canvas khơi tạo
initX:Vẽ tọa độ x bắt đầu của chuỗi
initY:Vẽ tọa độ y bắt đầu của chuỗi
lineHeight:Chiều cao của dòng, bạn có thể tự xác định giá trị
*/
  const canvasTextAutoLine = (
    str,
    canvas,
    initX,
    initY,
    lineHeight,
    colorText,
    borderText = false,
    borderColorText,
    boldText = '',
    italicText = '',
    underlineText = false,
    fontText = 'Arial',
    rotateText = 0,
    isFillRect = false,
    fillRectStyle = colorText + '6b',
    strokeTextLine = 5,
  ) => {
    if (str) {
      var ctx = canvas?.getContext('2d');
      var lineWidth = 0;
      var canvasWidth = canvas.width;
      // console.log('canvas', str.length, initX, initY, lineHeight);
      var lastSubStrIndex = 0;
      var pos = [];
      var bounds = {};

      for (let i = 0; i < str.length; i++) {
        lineWidth += ctx.measureText(str[i]).width;
        const metrics = ctx.measureText(str);
        pos = [initX, initY];

        bounds = {
          top: pos[1] - metrics.actualBoundingBoxAscent,
          right: pos[0] + metrics.actualBoundingBoxRight,
          bottom: pos[1] + metrics.actualBoundingBoxDescent,
          left: pos[0] - metrics.actualBoundingBoxLeft,
        };
        const center = [(bounds.left + bounds.right) / 2, (bounds.top + bounds.bottom) / 2];

        ctx.font = `${boldText} ${italicText} ${lineHeight}px ${fontText}`; //italic, bold,
        // ctx.fillStyle = colorText;
        ctx.strokeStyle = borderColorText;
        ctx.lineWidth = strokeTextLine;

        // -10 là dành cho lề
        if (lineWidth > canvasWidth - 10 - initX) {
          // Move the transform to center in the middle of the text
          ctx.save();
          ctx.translate(center[0], center[1]);
          ctx.rotate((parseInt(rotateText) * Math.PI) / 180);
          //Trừ initX, để ngăn chặn các vấn đề với đường viền
          if (borderText) {
            ctx.strokeText(str.substring(lastSubStrIndex, i), pos[0] - center[0], pos[1] - center[1]);
          }
          if (isFillRect) {
            ctx.fillStyle = fillRectStyle;
            ctx.beginPath();
            ctx.roundRect(
              pos[0] - center[0] - lineHeight / 5,
              pos[1] - center[1] - lineHeight / 1.2,
              ctx.measureText(str).width + lineHeight / 2.5,
              lineHeight,
              20,
            );
            // ctx.stroke();
            ctx.fill();
          }

          if (underlineText) {
            ctx.fillStyle = colorText;
            ctx.fillRect(pos[0] - center[0], pos[1] - center[1] + 10, ctx.measureText(str).width, 10);
          }

          ctx.fillStyle = colorText;
          ctx.fillText(str.substring(lastSubStrIndex, i), pos[0] - center[0], pos[1] - center[1]);
          ctx.restore();
          initY += lineHeight;
          lineWidth = 0;
          lastSubStrIndex = i;
        }

        if (i === str.length - 1) {
          // // Move the transform to center in the middle of the text
          ctx.save();
          ctx.translate(center[0], center[1]);
          ctx.rotate((parseInt(rotateText) * Math.PI) / 180);
          if (borderText) {
            ctx.strokeText(str.substring(lastSubStrIndex, i + 1), pos[0] - center[0], pos[1] - center[1]);
          }

          if (isFillRect) {
            ctx.fillStyle = fillRectStyle;
            ctx.beginPath();
            ctx.roundRect(
              pos[0] - center[0] - lineHeight / 5,
              pos[1] - center[1] - lineHeight / 1.2,
              ctx.measureText(str).width + lineHeight / 2.5,
              lineHeight,
              20,
            );
            // ctx.stroke();
            ctx.fill();
          }

          if (underlineText) {
            ctx.fillStyle = colorText;
            ctx.fillRect(pos[0] - center[0], pos[1] - center[1] + 10, ctx.measureText(str).width, 10);
          }

          ctx.fillStyle = colorText;
          ctx.fillText(str?.substring(lastSubStrIndex, i + 1), pos[0] - center[0], pos[1] - center[1]);
          ctx.restore();
        }
      }
    }
  };

  const addInput = async () => {
    if (amountOfText >= amountOfPrice) {
      setArrMauText([...arrMauText, text]);
      // setText(mauText);
      let j = 100;
      let valueTop = 0;
      let result = [];
      let resultPrice = [];
      for (let i = 0; i < parseInt(amountOfText); i++) {
        valueTop += j;
        let objText = {
          ...mauText,
          value: text?.value[i],
          option: { ...arrOption.option, top: arrOption.option.top + valueTop },
        };
        result.push(objText);
      }

      let b = 100;
      let valueTopPrice = 0;
      const canvas = document.createElement('canvas');
      const context = canvas?.getContext('2d');
      context.font = '80px Arial';

      for (let i = 0; i < parseInt(amountOfPrice); i++) {
        const textWidth = context.measureText(result[i]['value']).width;
        valueTopPrice += b;
        let objTextPrice = {
          ...mauTextPrice,
          value: text?.valuePrice[i],
          option: {
            ...mauTextPrice.option,
            left: mauTextPrice.option.left + 100 + textWidth,
            top: mauTextPrice.option.top + valueTopPrice,
          },
        };
        resultPrice.push(objTextPrice);
      }
      setArr([...result, ...resultPrice]);
      setMessNotifi({ isstt: false, notifi: '' });
    } else {
      setMessNotifi({ isstt: true, notifi: 'Số lượng GIÁ phải nhỏ hơn hoặc bằng số lượng SIM ' });
    }
  };
  const handleChange = (e) => {
    const text = e.target.value.split('\n');

    setArrMauText((s) => {
      const newArr = s.slice();
      newArr[0].value = text;

      return newArr;
    });
    let textLength = text.length;
    if (parseInt(amountOfText) <= textLength) {
      let j = 100;
      let valueTop = 0;
      let result = [];
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });
      for (let i = 0; i < parseInt(amountOfText); i++) {
        valueTop += j;
        let objText = {
          ...arrOption,
          value: text[i],
          option: arrSim[i]
            ? { ...arrSim[i]['option'] }
            : {
                ...arrOption.option,
                top: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['top'] + 100
                  : arrOption.option.top + valueTop,
                left: arrSim[arrSim.length - 1] ? arrSim[arrSim.length - 1]['option']['left'] : arrOption.option.left,
              },
        };
        result.push(objText);
      }
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });
      setArr([...result, ...arrPrice]);
      setMessNotifi({ isstt: false, notifi: '' });
    } else {
      setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng text trong ô SIM' });
    }
  };
  const handleChangeEditText = (soluong) => {
    const text = arrMauText[0];
    let textLength = text?.value?.length;
    if (parseInt(soluong || 0) > 0 && parseInt(soluong) <= textLength) {
      let j = 100;
      let valueTop = 0;
      let result = [];
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });
      for (let i = 0; i < parseInt(soluong); i++) {
        valueTop += j;
        let objText = {
          ...arrOption,
          value: text?.value[i],
          option: arrSim[i]
            ? { ...arrSim[i]['option'] }
            : {
                ...arrOption.option,
                top: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['top'] + 100
                  : arrOption.option.top + valueTop,
                left: arrSim[arrSim.length - 1] ? arrSim[arrSim.length - 1]['option']['left'] : arrOption.option.left,
              },
        };
        result.push(objText);
      }
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });
      setArr([...result, ...arrPrice]);
      setMessNotifi({ isstt: false, notifi: '' });
    } else {
      setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng text trong ô SIM' });
    }
  };
  const handleChangePrice = (e) => {
    const text = e.target.value.split('\n');
    setArrMauText((s) => {
      const newArr = s.slice();
      newArr[0].valuePrice = text;

      return newArr;
    });
    let textLength = text.length;
    if (parseInt(amountOfPrice) <= textLength) {
      let b = 100;
      let valueTopPrice = 0;
      let resultPrice = [];
      const canvas = document.createElement('canvas');
      const context = canvas?.getContext('2d');
      context.font = '80px Arial';
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });
      for (let i = 0; i < parseInt(amountOfPrice); i++) {
        const textWidth = context.measureText(arrMauText[0]['value'][i]).width;

        // console.log(index, arr);
        valueTopPrice += b;
        let objTextPrice = {
          ...mauTextPrice,
          value: text[i],
          option: arrPrice[i]
            ? { ...arrPrice[i]['option'] }
            : {
                ...mauTextPrice.option,
                top: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['top']
                  : mauTextPrice.option.top + valueTopPrice,
                left: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['left'] + 100 + textWidth
                  : mauTextPrice.option.left + 100 + textWidth,
              },
        };
        resultPrice.push(objTextPrice);
      }

      setArr([...arrSim, ...resultPrice]);
      setMessNotifi({ isstt: false, notifi: '' });
    } else {
      setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng text trong ô GIÁ' });
    }
  };
  const handleChangeEditPrice = (soluong) => {
    const text = arrMauText[0];
    let textLength = text?.valuePrice?.length;
    if (parseInt(soluong || 0) > 0 && parseInt(soluong) <= textLength) {
      let resultPrice = [];
      let b = 100;
      let valueTopPrice = 0;
      const canvas = document.createElement('canvas');
      const context = canvas?.getContext('2d');
      context.font = '80px Arial';
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });
      for (let i = 0; i < parseInt(soluong); i++) {
        const textWidth = context.measureText(arrMauText[0]['value'][i]).width;
        valueTopPrice += b;
        let objTextPrice = {
          ...mauTextPrice,
          value: text?.valuePrice[i],
          option: arrPrice[i]
            ? { ...arrPrice[i]['option'] }
            : {
                ...mauTextPrice.option,
                top: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['top']
                  : mauTextPrice.option.top + valueTopPrice,
                left: arrSim[arrSim.length - 1]
                  ? arrSim[arrSim.length - 1]['option']['left'] + 100 + textWidth
                  : mauTextPrice.option.left + 100 + textWidth,
              },
        };
        resultPrice.push(objTextPrice);
      }
      // console.log(arrSim);
      setArr([...arrSim, ...resultPrice]);
      setMessNotifi({ isstt: false, notifi: '' });
    } else {
      setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng text trong ô GIÁ' });
    }
  };

  const removeText = (index, id) => {
    const array = [...arr]; // make a separate copy of the array
    let result = [];

    let idremove = id - arrMauText[0]['valuePrice'].length - 1 > 0 ? id - arrMauText[0]['valuePrice'].length - 1 : 0;
    if (array[id]['option']['isprice']) {
      const filteredValue = arrMauText[0]['valuePrice'].filter((value, i) => i !== idremove);
      setArrMauText([{ value: arrMauText[0]['value'], valuePrice: filteredValue }]);
      result = [{ value: arrMauText[0]['value'], valuePrice: filteredValue }];
      let resultPrice = [];
      let b = 100;
      let valueTopPrice = 0;
      const canvas = document.createElement('canvas');
      const context = canvas?.getContext('2d');
      context.font = '80px Arial';

      for (let i = 0; i < parseInt(amountOfPrice); i++) {
        const textWidth = context.measureText(result[0]['value'][i]).width;
        valueTopPrice += b;
        let objTextPrice = {
          ...mauTextPrice,
          value: result[0]['valuePrice'][i],
          option: arr[id]
            ? { ...arr[id]['option'] }
            : {
                ...mauTextPrice.option,
                left: mauTextPrice.option.left + 100 + textWidth,
                top: mauTextPrice.option.top + valueTopPrice,
              },
        };
        resultPrice.push(objTextPrice);
      }
      let arrSim = arr.filter((item) => {
        return item.option.isprice === false;
      });

      setArr([...arrSim, ...resultPrice]);
    }

    if (!array[id]['option']['isprice']) {
      const filteredValue = arrMauText[0]['value'].filter((value, i) => i !== id);
      setArrMauText([{ value: filteredValue, valuePrice: arrMauText[0]['valuePrice'] }]);
      result = [{ value: filteredValue, valuePrice: arrMauText[0]['valuePrice'] }];

      let j = 100;
      let valueTop = 0;
      let resultsim = [];
      for (let i = 0; i < parseInt(amountOfText); i++) {
        valueTop += j;
        let objText = {
          ...mauText,
          value: result[0]['value'][i],
          option: arr[i] ? { ...arr[i]['option'] } : { ...mauText.option, top: mauText.option.top + valueTop },
        };
        resultsim.push(objText);
      }
      let arrPrice = arr.filter((item) => {
        return item.option.isprice === true;
      });
      setArr([...resultsim, ...arrPrice]);
    }
  };

  const onCloseToolbar = () => {
    setActionEditor({ action: '', toolbar: 0, id: 0 });
    setOpenAddPrice(false);
  };
  const onBackToolbar = (id) => {
    setActionEditor({ action: 'toolbar', toolbar: 7, id: id });
  };

  const toggleDrawer = (open) => {
    setIsOpenDrawer({ ...isOpenDrawer, isOpenDrawer: open });
    if (!open) {
      contextSearch.toggleBoLoc(false);
    }
  };

  const handleDrawerPhotoAlbums = (arrPhoto) => {
    setArr(arrPhoto.arrText);
    setNameImageSV(arrPhoto.name);
    let arrSim = arrPhoto.arrText.filter((item) => {
      return item.option.isprice === false;
    });
    let arrPrice = arrPhoto.arrText.filter((item) => {
      return item.option.isprice === true;
    });
    arrSim = arrSim.length > 0 ? arrSim.map((obj) => obj.value) : [];
    arrPrice = arrPrice.length > 0 ? arrPrice.map((obj) => obj.value) : [];
    setArrMauText([{ value: arrSim, valuePrice: arrPrice }]);
    setAmountOfText(arrSim.length || 0);
    setAmountOfPrice(arrPrice.length || 0);
    if (canvasRef.current) {
      const drawImage = () => {
        const base_image = new Image();
        base_image.setAttribute('crossorigin', 'anonymous');
        base_image.src = arrPhoto.image;
        base_image.onload = () => {
          setImage(base_image);
        };
      };
      drawImage();
    }
  };

  // ấn giữ chuột để tăng move,size..
  const timeoutClearUp = () => {
    if (changeTimer.current) {
      clearInterval(changeTimer.current);
      changeTimer.current = null;
    }
  };

  const increment = (action, key, index) => {
    changeTimer.current = setInterval(() => {
      let updateArrStyle;
      setArr((prev) => {
        if (action === 'down') {
          updateArrStyle = prev.map((obj, i) => {
            if (i === index) {
              return { ...obj, option: { ...obj.option, [key]: parseInt(obj.option[key] - 1) } };
            }
            return obj;
          });
        } else {
          updateArrStyle = prev.map((obj, i) => {
            if (i === index) {
              return { ...obj, option: { ...obj.option, [key]: parseInt(obj.option[key] + 1) } };
            }
            return obj;
          });
        }

        return updateArrStyle;
      });
    }, 40);
  };

  const onCloseAddText = () => {
    setPos({ top: '', left: '' });
  };

  const textHittest = (x, y, w, h, textIndex) => {
    return (
      x >= arr[textIndex]['option']['left'] &&
      x <= Math.round(arr[textIndex]['option']['left'] + w) &&
      y >= Math.round(arr[textIndex]['option']['top'] - h) &&
      y <= arr[textIndex]['option']['top']
    );
  };
  const handleTouchStart = useCallback((event) => {
    // console.log(88888);
    let canvas = canvasRef.current;
    let ctx = canvas?.getContext('2d');
    let rect = canvas.getBoundingClientRect(),
      scaleX = canvas.width / rect.width,
      scaleY = canvas.height / rect.height,
      x = Math.round((event.touches[0].clientX - rect.left) * scaleX),
      y = Math.round((event.touches[0].clientY - rect.top) * scaleY);

    arr.map((text, i) => {
      let measure = ctx.measureText(text['value']);
      let lineHeight = text['option']['size'];
      setStartX({ start: text['option']['left'], point: x });
      let isMoveText = textHittest(x, y, measure.width, lineHeight, i);
      if (isMoveText) {
        setSelectedText(i);
        setActionEditor({ ...actionEditor, action: 'toolbar', toolbar: 0, id: i });
      }
    });
  });

  const onHandleMouseDown = (evt) => {
    evt.preventDefault();
    // console.log(77777);
    let canvas = canvasRef.current;
    let ctx = canvas?.getContext('2d');
    let rect = canvas.getBoundingClientRect(),
      scaleX = canvas.width / rect.width,
      scaleY = canvas.height / rect.height,
      x = Math.round((evt.clientX - rect.left) * scaleX),
      y = Math.round((evt.clientY - rect.top) * scaleY);
    arr.map((text, i) => {
      let measure = ctx.measureText(text['value']);
      let lineHeight = text['option']['size'];
      setStartX({ start: text['option']['left'], point: x });
      let isMoveText = textHittest(x, y, measure.width, lineHeight, i);
      if (isMoveText) {
        setSelectedText(i);
        setActionEditor({ ...actionEditor, action: 'toolbar', id: i });
        setOpenAddPrice(false);
      }
    });
  };

  const onHandleTouchEnd = useCallback(() => {
    setSelectedText(-1);
  });

  const onHandleMouseUp = (evt) => {
    evt.preventDefault();
    setSelectedText(-1);
  };

  const onHandleMouseOut = (evt) => {
    evt.preventDefault();
    setSelectedText(-1);
  };
  const handleTouchMove = useCallback((event) => {
    if (selectedText < 0) {
      return;
    }
    // console.log(6666);
    let canvas = canvasRef.current;
    let rect = canvas.getBoundingClientRect(),
      scaleX = canvas.width / rect.width,
      scaleY = canvas.height / rect.height,
      x = Math.round((event.touches[0].clientX - rect.left) * scaleX),
      y = Math.round((event.touches[0].clientY - rect.top) * scaleY);
    setArr((prev) => {
      return prev.map((obj, i) => {
        if (i === selectedText) {
          let leftX = x - (startX.point - startX.start);
          return { ...obj, option: { ...obj.option, left: leftX, top: y } };
        }
        return obj;
      });
    });
    drawText();
  });

  const onHandleMouseMove = (evt) => {
    if (selectedText < 0) {
      return;
    }
    evt.preventDefault();
    // console.log(55555);
    let canvas = canvasRef.current;
    let rect = canvas.getBoundingClientRect(),
      scaleX = canvas.width / rect.width,
      scaleY = canvas.height / rect.height,
      x = Math.round((evt.clientX - rect.left) * scaleX),
      y = Math.round((evt.clientY - rect.top) * scaleY);

    setArr((prev) => {
      return prev.map((obj, i) => {
        if (i === selectedText) {
          let leftX = x - (startX.point - startX.start);
          return { ...obj, option: { ...obj.option, left: leftX, top: y } };
        }
        return obj;
      });
    });
    drawText();
  };

  const drawText = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    ctx.canvas.width = image.width;
    ctx.canvas.height = image.height;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // ctx.fillText(text, style?.left, style?.top);
    arr?.map((text, i) => {
      canvasTextAutoLine(
        text.value,
        canvas,
        text.option?.left,
        text.option?.top,
        text.option?.size,
        text.option?.color,
        text.option?.border,
        text.option?.borderColor,
        text.option?.bold,
        text.option?.italic,
        text.option?.underline,
        text.option?.font,
        text.option?.rotate,
        text.option?.isFillRect,
        text.option?.fillRectStyle,
        text.option?.strokeTextLine,
      );
    });
  };
  function dataURItoURL(dataURI) {
    return URL.createObjectURL(dataURItoBlob(dataURI));
  }

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }
  const saveTextImageDefault = async () => {
    if (nameImageSV) {
      const getSampleImage = getLocalStorage('ImageSample') || [];
      setLocalStorage(
        'ImageSample',
        getSampleImage?.map((item) =>
          item.name === nameImageSV
            ? {
                ...item,
                image: image.src,
                amount: parseInt(amountOfText),
                category: anetwork,
                arrText: arr,
                issv: false,
              }
            : item,
        ),
      );
      setMessNotifi({ isstt: true, notifi: 'Cập nhật thành công' });
      setTimeout(() => {
        setMessNotifi({ isstt: false, notifi: '' });
      }, 2000);
    } else {
      const getSampleImage = getLocalStorage('ImageSample') || [];
      const objTextImageDefault = {
        name: Date.now(),
        image: image.src,
        amount: parseInt(amountOfText),
        arrText: arr,
        category: anetwork,
        issv: false,
      };
      let arrImage = [...getSampleImage, objTextImageDefault];
      setLocalStorage('ImageSample', arrImage);
      setMessNotifi({ isstt: true, notifi: 'Lưu mẫu thành công' });
      setTimeout(() => {
        setMessNotifi({ isstt: false, notifi: '' });
      }, 2000);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (nameImageSV) {
      const objTextImageDefault = {
        name: nameImageSV,
        category: anetwork,
        amount: amountOfText,
        arrText: JSON.stringify(arr),
        status: 1,
      };
      const formData = new FormData();
      formData.append('name', objTextImageDefault.name);
      formData.append('image', selectedImage);
      formData.append('data', JSON.stringify(objTextImageDefault));

      const upload = await updateImageSample(formData);
      if (upload.message === 'OK') {
        setMessNotifi({ isstt: true, notifi: 'Cập nhật lại mẫu thành công' });
        setTimeout(() => {
          setMessNotifi({ isstt: false, notifi: '' });
        }, 2000);
      } else {
        setMessNotifi({ isstt: true, notifi: 'Cập nhật lại mẫu không thành công' });
        setTimeout(() => {
          setMessNotifi({ isstt: false, notifi: '' });
        }, 2000);
      }
    } else {
      const objTextImageDefault = {
        name: Date.now(),
        amount: amountOfText,
        category: anetwork,
        arrText: JSON.stringify(arr),
        status: 1,
      };
      const formData = new FormData();
      formData.append('name', objTextImageDefault.name);
      formData.append('image', selectedImage);
      formData.append('data', JSON.stringify(objTextImageDefault));

      const upload = await uploadImageSample(formData);
      if (upload.message === 'OK') {
        setMessNotifi({ isstt: true, notifi: '' });
        setTimeout(() => {
          setMessNotifi({ isstt: false, notifi: 'Lưu mẫu thành công ' });
        }, 2000);
      } else {
        setMessNotifi({ isstt: true, notifi: 'Lưu mẫu không thành công' });
        setTimeout(() => {
          setMessNotifi({ isstt: false, notifi: '' });
        }, 2000);
      }
    }
  };

  const removeImageSample = async (nameimage) => {
    const body = {
      name: nameimage,
    };
    const result = await removeImageSamples(body);
    if (result.message === 'OK') {
      let arrSample = imageSample.filter((item) => {
        return item.name !== nameimage;
      });
      setImageSample(arrSample);
      setMessNotifi({ isstt: true, notifi: 'Xóa thành công' });
      setTimeout(() => {
        setMessNotifi({ isstt: false, notifi: '' });
      }, 2000);
    }
  };
  const removeImageSampleLocal = async (nameimage) => {
    let arr = getLocalStorage('ImageSample');
    if (arr.length > 0) {
      let arrSample = imageSample.filter((item) => {
        return item.name !== nameimage;
      });
      setImageSample(arrSample);

      let arrLocal = arr.filter((item) => {
        return item.name !== nameimage;
      });

      setLocalStorage('ImageSample', arrLocal);
      setMessNotifi({ isstt: true, notifi: 'Xóa thành công' });
      setTimeout(() => {
        setMessNotifi({ isstt: false, notifi: '' });
      }, 2000);
    }
  };

  // useEffect(() => {
  //   handleExportImageSample();
  // }, [imageSample]);
  const handleExportImageSample = (imageSample) => {
    // setArrCanvasImage([]);
    setLoading(true);
    imageSample.map(async (item, i) => {
      let resultImage = await drawImageSample(item.name, item);

      // setTimeout(() => {
      // setLoading(true);
      setArrCanvasImage((prev) => {
        return [...prev, { ...item, imageblo: dataURItoURL(resultImage) }];
      });
      // }, i * 500);
      setLoading(false);
    });
  };

  const drawImageSample = async (name, arrImageSample) => {
    // console.log(123);
    const promise = new Promise(async (resolve, reject) => {
      let srcImage;
      if (arrImageSample?.issv) {
        srcImage = `${auth[hostname]['API_URL_SIMSO']}/anhdemo?name=${name}.jpg`;
      } else {
        srcImage = arrImageSample?.image;
      }
      // const image = await loadImage(srcImage);
      const image = new Image();
      image.setAttribute('crossorigin', 'anonymous');
      image.src = srcImage;
      image.onload = () => {
        // Tạo canvas với kích thước tương ứng với ảnh gốc
        const canvas = document.createElement('canvas');
        const ctx = canvas?.getContext('2d');
        ctx.canvas.width = image.width;
        ctx.canvas.height = image.height;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        arrImageSample?.arrText.map((text) => {
          canvasTextAutoLine(
            text.value,
            canvas,
            text.option?.left,
            text.option?.top,
            text.option?.size,
            text.option?.color,
            text.option?.border,
            text.option?.borderColor,
            text.option?.bold,
            text.option?.italic,
            text.option?.underline,
            text.option?.font,
            text.option?.rotate,
            text.option?.isFillRect,
            text.option?.fillRectStyle,
            text.option?.strokeTextLine,
          );
        });
        const savable = new Image();
        savable.src = canvas.toDataURL('image/jpeg', 0.2);
        resolve(savable.src);
      };
    });
    return promise;
  };

  // VẼ CANVAS CHI TIẾT RA ẢNH
  const canvasDetail = async (imageSample, arrTextVBs) => {
    setLoading(true);
    // console.log(Math.ceil(parseInt(arrTextVBs?.value?.length)), amountOfText);
    const promise = new Promise(async (resolve, reject) => {
      if (arrTextVBs?.value.length > 0) {
        let checkImage = imageSample?.filter((item) => {
          return item.name === nameImageSV;
        });
        const srcImage = checkImage[0].issv
          ? `${auth[hostname]['API_URL_SIMSO']}/anhdemo?name=${checkImage[0]['name']}.jpg`
          : checkImage[0]['image'];
        // const image = await loadImage(srcImage);
        setArrCanvasImageDetail([]);
        const image = new Image();
        image.setAttribute('crossorigin', 'anonymous');
        image.src = srcImage;
        image.onload = () => {
          // Tạo canvas với kích thước tương ứng với ảnh gốc
          const canvas = document.createElement('canvas');
          const ctx = canvas?.getContext('2d');
          ctx.canvas.width = image.width;
          ctx.canvas.height = image.height;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (arrTextVBs?.value?.length >= parseInt(amountOfText)) {
            let arrSim = arr.filter((item) => {
              return item.option.isprice === false;
            });
            let arrPrice = arr.filter((item) => {
              return item.option.isprice === true;
            });
            // console.log(arrPrice);

            const iterationsSim = Math.ceil(arrTextVBs?.value.length / amountOfText);
            const iterationsPrice = Math.ceil(arrTextVBs?.valuePrice.length / amountOfPrice);
            let amountLimit =
              authToken?.data?.permission === '1'
                ? iterationsSim <= 2
                  ? iterationsSim
                  : 2
                : iterationsSim <= 15
                ? iterationsSim
                : 15;
            for (let i = 0; i < amountLimit; i++) {
              const startIndex = i * amountOfText;
              const endIndex = (i + 1) * amountOfText;
              let batch = arrTextVBs?.value?.slice(startIndex, endIndex);
              let batchPrice;

              if (arrPrice.length > 0) {
                for (let j = 0; j < iterationsPrice; j++) {
                  const startIndex = i * amountOfPrice;
                  const endIndex = (i + 1) * amountOfPrice;
                  batchPrice =
                    arrTextVBs?.valuePrice?.slice(startIndex, endIndex).length > 0
                      ? arrTextVBs?.valuePrice?.slice(startIndex, endIndex)
                      : arrTextVBs?.valuePrice?.slice(0, 1);
                }
              }

              arrSim.forEach((item, index) => {
                item.value = batch[index];
              });
              if (batchPrice?.length > 0) {
                // console.log(arrPrice, iterationsPrice, batchPrice);
                arrPrice?.forEach((item, index) => {
                  item.value = batchPrice[index];
                });
              } else {
                arrPrice = [];
              }

              // console.log(arrPrice);

              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              arrSim.map((text) => {
                canvasTextAutoLine(
                  text.value,
                  canvas,
                  text.option?.left,
                  text.option?.top,
                  text.option?.size,
                  text.option?.color,
                  text.option?.border,
                  text.option?.borderColor,
                  text.option?.bold,
                  text.option?.italic,
                  text.option?.underline,
                  text.option?.font,
                  text.option?.rotate,
                  text.option?.isFillRect,
                  text.option?.fillRectStyle,
                  text.option?.strokeTextLine,
                );
              });
              arrPrice.map((text) => {
                canvasTextAutoLine(
                  text.value,
                  canvas,
                  text.option?.left,
                  text.option?.top,
                  text.option?.size,
                  text.option?.color,
                  text.option?.border,
                  text.option?.borderColor,
                  text.option?.bold,
                  text.option?.italic,
                  text.option?.underline,
                  text.option?.font,
                  text.option?.rotate,
                  text.option?.isFillRect,
                  text.option?.fillRectStyle,
                  text.option?.strokeTextLine,
                );
              });
              const savable = new Image();
              savable.src = canvas.toDataURL('image/jpeg', 1);
              setArrCanvasImageDetail((prev) => {
                return [...prev, savable.src];
              });
              setLoading(false);
              resolve(savable.src);
            }
          }
        };
      } else {
        resolve('');
      }
    });

    return promise;
  };

  const handleDrawerPhotoDetail = async (arrPhoto) => {
    setArrCanvasImageDetail([]);
    setArr(arrPhoto.arrText);
    setNameImageSV(arrPhoto.name);
    let arrSim = arrPhoto.arrText.filter((item) => {
      return item.option.isprice === false;
    });
    let arrPrice = arrPhoto.arrText.filter((item) => {
      return item.option.isprice === true;
    });
    arrSim = arrSim.length > 0 ? arrSim.map((obj) => obj.value) : [];
    arrPrice = arrPrice.length > 0 ? arrPrice.map((obj) => obj.value) : [];
    setArrMauText([{ value: arrSim, valuePrice: arrPrice }]);
    setAmountOfText(arrSim.length || 0);
    setAmountOfPrice(arrPrice.length || 0);
  };

  const handleIsInFoImagePhoi = async (amount, network) => {
    setImageSample([]);
    setArrCanvasImage([]);
    setLoading(true);
    setPage(1);
    const getSampleImage = getLocalStorage('ImageSample') || [];
    const resultCount = await getAllImageSampleCount(amount, network);
    if (resultCount?.totalRows > 0) {
      setTotalRows(resultCount?.totalRows);
      const result = await getAllImageSample(amount, network);
      if (result.length > 0) {
        let arrImage = result.map((obj) => {
          return {
            ...obj,
            arrText: JSON.parse(obj.arrText),
            image: `${auth[hostname]['API_URL_SIMSO']}/anhdemo?name=${obj.name}.jpg`,
            issv: true,
          };
        });
        let arrLocal = getSampleImage.filter((item) => {
          return parseInt(item.amount) === parseInt(amount) && item.category === network;
        });
        handleExportImageSample([...arrLocal, ...arrImage]);
        setImageSample([...arrLocal, ...arrImage]);
      }
    } else {
      let arrLocal = getSampleImage.filter((item) => {
        return parseInt(item.amount) === parseInt(amount) && item.category === network;
      });
      handleExportImageSample(arrLocal);
      if (arrLocal.length > 0) {
        setLoading(false);
        setTotalRows(arrLocal.length);
      } else {
        setLoading(false);
        setTotalRows(0);
      }
    }
  };

  const xemthemImage = () => {
    if (Math.ceil(totalRows / 5) > page) {
      setPage((page) => page + 1);
      apiPageImageSample(amountOfText, anetwork, page + 1);
    }
  };

  const apiPageImageSample = async (amount, network, page) => {
    const result = await getAllImageSample(amount, network, page);
    if (result.length > 0) {
      let arrImage = result.map((obj) => {
        return {
          ...obj,
          arrText: JSON.parse(obj.arrText),
          image: `${auth[hostname]['API_URL_SIMSO']}/anhdemo?name=${obj.name}.jpg`,
          issv: true,
        };
      });
      let arrResult = imageSample.concat(arrImage);
      handleExportImageSample(arrImage);
      setImageSample(arrResult);
    }
  };

  function downloadImageLayout3(imageUrl) {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const downloadAllImageLayout3 = () => {
    let img = zip.folder('images');
    let checkImage = imageSample?.filter((item) => {
      return item.name === nameImageSV;
    });
    const srcImage = checkImage[0].issv
      ? `${auth[hostname]['API_URL_SIMSO']}/anhdemo?name=${nameImageSV}.jpg`
      : checkImage[0]['image'];
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = srcImage;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas?.getContext('2d');
      ctx.canvas.width = image.width;
      ctx.canvas.height = image.height;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // ctx.fillText(text, style?.left, style?.top);
      if (arrTextVBs?.value.length > parseInt(amountOfText)) {
        let arrSim = arr.filter((item) => {
          return item.option.isprice === false;
        });
        let arrPrice = arr.filter((item) => {
          return item.option.isprice === true;
        });

        const iterationsSim = Math.ceil(arrTextVBs?.value?.length / amountOfText);
        const iterationsPrice =
          arrTextVBs?.valuePrice.length > 0 ? Math.ceil(arrTextVBs?.valuePrice?.length / amountOfPrice) : 0;
        for (let i = 0; i < iterationsSim; i++) {
          const startIndex = i * amountOfText;
          const endIndex = (i + 1) * amountOfText;
          let batch = arrTextVBs?.value?.slice(startIndex, endIndex);
          let batchPrice;
          // if (iterationsPrice > 0) {
          //   for (let j = 0; j < iterationsPrice; j++) {
          //     const startIndex = i * amountOfPrice;
          //     const endIndex = (i + 1) * amountOfPrice;
          //     batchPrice = arrTextVBs?.valuePrice?.slice(startIndex, endIndex);
          //   }
          // }

          if (arrPrice.length > 0) {
            for (let j = 0; j < iterationsPrice; j++) {
              const startIndex = i * amountOfPrice;
              const endIndex = (i + 1) * amountOfPrice;
              batchPrice =
                arrTextVBs?.valuePrice?.slice(startIndex, endIndex).length > 0
                  ? arrTextVBs?.valuePrice?.slice(startIndex, endIndex)
                  : arrTextVBs?.valuePrice?.slice(0, 1);
            }
          }

          arrSim.forEach((item, index) => {
            item.value = batch[index];
          });
          if (batchPrice?.length > 0) {
            // console.log(arrPrice, iterationsPrice, batchPrice);
            arrPrice?.forEach((item, index) => {
              item.value = batchPrice[index];
            });
          } else {
            arrPrice = [];
          }
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          arrSim.map((text) => {
            canvasTextAutoLine(
              text.value,
              canvas,
              text.option?.left,
              text.option?.top,
              text.option?.size,
              text.option?.color,
              text.option?.border,
              text.option?.borderColor,
              text.option?.bold,
              text.option?.italic,
              text.option?.underline,
              text.option?.font,
              text.option?.rotate,
              text.option?.isFillRect,
              text.option?.fillRectStyle,
              text.option?.strokeTextLine,
            );
          });
          arrPrice.map((text) => {
            canvasTextAutoLine(
              text.value,
              canvas,
              text.option?.left,
              text.option?.top,
              text.option?.size,
              text.option?.color,
              text.option?.border,
              text.option?.borderColor,
              text.option?.bold,
              text.option?.italic,
              text.option?.underline,
              text.option?.font,
              text.option?.rotate,
              text.option?.isFillRect,
              text.option?.fillRectStyle,
              text.option?.strokeTextLine,
            );
          });
          const savable = new Image();
          savable.src = canvas.toDataURL();
          img.file(`${i}.png`, savable.src.substring(savable.src.indexOf(',') + 1), { base64: true });
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // see FileSaver.js
          saveAs(content, 'images.zip');
        });
      }
    };
  };

  return (
    <>
      <div className={cx(selectedText < 0 ? 'touchactionAuto' : 'touchactionNone')}>
        {layout === 2 && (
          <Container
            ref={prevScrollY}
            maxWidth="sm"
            // sx={{
            //   pb: '55px',
            //   mt: '55px',
            // }}
          >
            <ButtonUI
              startIcon={<ReplyIcon />}
              color="success"
              onClick={() => {
                setLayout(1);
              }}
              small
              sx={{ mt: 2 }}
            >
              Quay lại thư viện
            </ButtonUI>
            <Grid container sx={{ mt: 2 }}>
              <Fragment>
                <Drawer
                  anchor={'right'}
                  open={isOpenDrawer.isOpenDrawer}
                  onClose={() => toggleDrawer(false)}
                  sx={{
                    '& .MuiPaper-root': {
                      borderTopLeftRadius: '10px',
                    },
                  }}
                >
                  {isOpenDrawer.action === 'Albums' && (
                    <Box>
                      <Box sx={{ width: 350, p: 1 }} className={cx('title-sort-sim')}>
                        <b>THƯ VIỆN ẢNH CỦA BẠN</b>
                      </Box>
                      <Box sx={{ width: 350, p: 1 }}>
                        <Grid container spacing={1}>
                          <div className={cx('box-list-subcate')}>
                            <b>Số Lượng Sim trên 1 ảnh:</b>
                            <div className={cx('list-subcate')}>
                              <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
                                {authToken?.data?.permission === '1' &&
                                  soluongTextPhoi.map((slp, i) => (
                                    <Button
                                      key={i}
                                      variant={amountOfText === slp ? 'contained' : 'outlined'}
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        handleIsInFoImagePhoi(slp, anetwork);
                                        setAmountOfText(slp);
                                      }}
                                      disabled={authToken?.data?.permission === '1' && i < 2 ? false : true || loading}
                                    >
                                      {slp}
                                    </Button>
                                  ))}
                                {authToken?.data?.permission !== '1' &&
                                  soluongTextPhoi.map((slp, i) => (
                                    <Button
                                      key={i}
                                      variant={amountOfText === slp ? 'contained' : 'outlined'}
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        handleIsInFoImagePhoi(slp, anetwork);
                                        setAmountOfText(slp);
                                        setLoading(true);
                                      }}
                                      disabled={loading}
                                    >
                                      {slp}
                                    </Button>
                                  ))}
                              </Stack>
                            </div>
                          </div>

                          <div className={cx('box-list-subcate')}>
                            <b>Loại ảnh:</b>
                            <div className={cx('list-subcate')}>
                              <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
                                {(authToken?.data?.permission === '2' || authToken?.data?.permission === '3') &&
                                  loaiAnhPhoi?.map((phoi, i) => (
                                    <Button
                                      key={i}
                                      variant={anetwork === phoi.link ? 'contained' : 'outlined'}
                                      size="small"
                                      color="success"
                                      onClick={() => {
                                        handleIsInFoImagePhoi(amountOfText, phoi.link);
                                        setAnetwork(phoi.link);
                                      }}
                                      disabled={loading}
                                    >
                                      {phoi.title}
                                    </Button>
                                  ))}
                                {loaiAnh.map((net, i) => (
                                  <Button
                                    key={i}
                                    variant={anetwork === net.link ? 'contained' : 'outlined'}
                                    size="small"
                                    color="success"
                                    onClick={() => {
                                      handleIsInFoImagePhoi(amountOfText, net.link);
                                      setAnetwork(net.link);
                                    }}
                                    disabled={loading}
                                  >
                                    {net.title}
                                  </Button>
                                ))}
                              </Stack>
                            </div>
                          </div>
                          {loading && (
                            <Box sx={{ width: '100%', mt: 1 }}>
                              <LinearProgress />
                            </Box>
                          )}

                          {/* <Masonry columns={2} spacing={1}>
                            {arrCanvasImage.map((item, index) => (
                              <div key={index}>
                                <Stack
                                  direction={'row'}
                                  className={cx('css-image-boder')}
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                >
                                  <b>DS_{index + 1}</b>
                                  {getLocalStorage('sv') === '1' && item.issv ? (
                                    <ButtonUI small color="error" onClick={() => removeImageSample(item.name)}>
                                      Xóa
                                    </ButtonUI>
                                  ) : (
                                    !item.issv && (
                                      <ButtonUI small color="error" onClick={() => removeImageSampleLocal(item.name)}>
                                        Xóa
                                      </ButtonUI>
                                    )
                                  )}
                                </Stack>
                                <img
                                  src={`${item.imageblo}`}
                                  alt={index}
                                  loading="lazy"
                                  style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                  }}
                                  onClick={() => {
                                    handleDrawerPhotoAlbums(item);
                                    toggleDrawer(false);
                                  }}
                                />
                              </div>
                            ))}
                          </Masonry> */}

                          <ImageList variant="quilted" cols={2} gap={6}>
                            {arrCanvasImage.map((item, index) => (
                              <ImageListItem key={item.name}>
                                <img
                                  src={`${item.imageblo}`}
                                  alt={item.name}
                                  loading="lazy"
                                  onClick={() => {
                                    handleDrawerPhotoAlbums(item);
                                    toggleDrawer(false);
                                  }}
                                  style={{ height: 'auto' }}
                                />
                                <ImageListItemBar
                                  position="bottom"
                                  title={`DS_${index + 1}`}
                                  actionIcon={
                                    getLocalStorage('sv') === '1' && item.issv ? (
                                      <ButtonUI small color="error" onClick={() => removeImageSample(item.name)}>
                                        Xóa
                                      </ButtonUI>
                                    ) : (
                                      !item.issv && (
                                        <ButtonUI small color="error" onClick={() => removeImageSampleLocal(item.name)}>
                                          Xóa
                                        </ButtonUI>
                                      )
                                    )
                                  }
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                          {Math.ceil(totalRows / 5) > page && !loading && (
                            <Button
                              color="success"
                              variant="contained"
                              onClick={xemthemImage}
                              sx={{ mt: 1 }}
                              disabled={authToken?.data?.permission === '1'}
                            >
                              {authToken?.data?.permission !== '1'
                                ? `XEM THÊM ẢNH ( ${totalRows - page * 5} ảnh còn)`
                                : ''}

                              <b style={{ color: 'red' }}>
                                {authToken?.data?.permission === '1' ? 'Nâng cấp để xem thêm' : ''}
                              </b>
                            </Button>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Drawer>
              </Fragment>

              <Grid item xs={12} md={12}>
                <AppBar
                  position="static"
                  sx={{
                    top: 'auto',
                    bottom: 10,
                    borderRadius: 10,
                    backgroundColor: '#fff',
                    boxShadow:
                      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                    mb: 2,
                  }}
                >
                  <input hidden ref={uploadRef} accept="image/*" type="file" onChange={handleUploadFile} />
                  {actionEditor.action !== 'toolbar' && (
                    <Box>
                      <BottomNavigation
                        showLabels
                        sx={{
                          borderRadius: 10,
                          height: '45px',
                          ml: 2,
                          mr: 2,
                        }}
                      >
                        {arr.length === 0 && (
                          <MuiBottomNavigationAction
                            label="Add Text"
                            color="#000"
                            icon={<FormatSizeIcon />}
                            onClick={() => {
                              handleOpen();
                            }}
                          />
                        )}
                        <MuiBottomNavigationAction
                          label="Mẫu Ảnh"
                          icon={<WallpaperIcon />}
                          onClick={() => {
                            setIsOpenDrawer({ isOpenDrawer: true, action: 'Albums' });
                            setOpenSpeedDial(false);
                            // toggleDrawer(false);
                            // handleIsInFoImagePhoi(amountOfText, anetwork);
                          }}
                        />
                        <MuiBottomNavigationAction
                          label="Upload"
                          icon={<CameraAltIcon />}
                          onClick={(e) => {
                            uploadRef.current.click();
                          }}
                        />
                        <MuiBottomNavigationAction label="Download" icon={<CloudDownloadIcon />} onClick={download} />
                      </BottomNavigation>
                    </Box>
                  )}

                  {actionEditor.action === 'toolbar' && (
                    <Box>
                      <BottomNavigation
                        showLabels
                        sx={{
                          borderRadius: 10,
                          height: '45px',
                          minWidth: 'auto',
                          ml: 2,
                          mr: 2,
                        }}
                      >
                        <MuiBottomNavigationAction
                          icon={
                            <ArrowCircleLeftOutlinedIcon
                              sx={{
                                color: 'blue',
                                border: '1px solid blue',
                                borderRadius: 50,
                              }}
                            />
                          }
                          onClick={onCloseToolbar}
                        />
                        <MuiBottomNavigationAction
                          icon={
                            <CircleIcon
                              sx={{
                                color: arr && arr[actionEditor.id]?.option?.color,
                                border: '1px solid black',
                                borderRadius: 50,
                              }}
                            />
                          }
                          onClick={() => setActionEditor({ ...actionEditor, toolbar: 3 })}
                        />
                        <MuiBottomNavigationAction
                          icon={<FormatSizeIcon />}
                          onClick={() => {
                            setActionEditor({ ...actionEditor, toolbar: 1 });
                            setOpenAddPrice(false);
                          }}
                        />

                        <MuiBottomNavigationAction
                          label={arr && arr[actionEditor.id]?.option?.size}
                          onClick={() => setActionEditor({ ...actionEditor, toolbar: 4 })}
                        />
                        <MuiBottomNavigationAction
                          icon={<RotateLeftIcon />}
                          onClick={() => setActionEditor({ ...actionEditor, toolbar: 5 })}
                        />
                        <MuiBottomNavigationAction
                          icon={<OpenWithOutlinedIcon />}
                          onClick={() => setActionEditor({ ...actionEditor, toolbar: 6 })}
                        />
                        <MuiBottomNavigationAction
                          icon={<DeleteOutlinedIcon className={cx('color-red')} />}
                          onClick={() => {
                            removeText(selectedText, actionEditor.id);
                            setActionEditor({ action: '', toolbar: 0, id: 0 });
                          }}
                        />
                      </BottomNavigation>
                    </Box>
                  )}
                </AppBar>
                {actionEditor.action === 'toolbar' && (
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ mb: 1 }}>
                    {arr.length > 0 && (
                      <ButtonUI small color="error" onClick={saveTextImageDefault}>
                        Lưu Mẫu Ảnh
                      </ButtonUI>
                    )}
                    <b>| Bạn đang chỉnh sửa text ({arr[actionEditor.id]?.value}):</b>
                  </Stack>
                )}
                {actionEditor.action !== 'toolbar' && (
                  <Stack direction={'row'} justifyContent={'center'} sx={{ mb: 1 }}>
                    {arr.length > 0 && (
                      <ButtonUI small color="error" onClick={saveTextImageDefault}>
                        Lưu Mẫu Ảnh
                      </ButtonUI>
                    )}
                  </Stack>
                )}
                <Stack direction={'row'} justifyContent={'center'} sx={{ mb: 1 }}>
                  {arr.length > 0 && getLocalStorage('sv') > 0 && (
                    <ButtonUI small color="error" onClick={handleFormSubmit}>
                      Lưu Mẫu Lên Website
                    </ButtonUI>
                  )}
                </Stack>
                {messNotifi.isstt === true && (
                  <Stack direction="row" justifyContent={'center'} spacing={1.5} sx={{ color: 'red' }}>
                    {messNotifi.notifi}
                  </Stack>
                )}
                <canvas
                  ref={canvasRef}
                  onMouseDown={onHandleMouseDown}
                  onMouseUp={onHandleMouseUp}
                  onMouseOut={onHandleMouseOut}
                  onMouseMove={onHandleMouseMove}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={onHandleTouchEnd}
                  onTouchMove={handleTouchMove}
                ></canvas>
                {/* <div
              style={{
                borderRadius: '10px',
                position: 'fixed',
                width: pos.width + 'px',
                border: '1px solid black',
                height: pos.height / 2 + 'px',
                top: pos.top + 'px',
                left: pos.left + 'px',
              }}
            ></div> */}

                {/* {pos.top && pos.left && (
              <div
                className={cx('btn-add-text')}
                style={{
                  left: pos.left,
                  top: pos.top,
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <ButtonUI startIcon={<FormatSizeIcon />} color="error" onClick={handleOpen}>
                    Add Text
                  </ButtonUI>
                  <HighlightOffOutlinedIcon sx={{ float: 'right', color: '#ccfb5a' }} onClick={onCloseAddText} />
                </Stack>
              </div>
            )} */}
                {actionEditor.action === 'toolbar' && actionEditor.toolbar !== 0 && (
                  <div className={cx('toolbar-editor')}>
                    <HighlightOffOutlinedIcon sx={{ float: 'right', color: 'red' }} onClick={onCloseToolbar} />
                    {/* <ArrowCircleLeftOutlinedIcon
                  sx={{ float: 'left', color: '#5afb9a' }}
                  onClick={() => onBackToolbar(actionEditor.id)}
                /> */}
                    <Box sx={{ p: 2, mt: 1, mb: 1 }}>
                      {/* EDIT TEXT */}
                      {actionEditor.toolbar === 1 && (
                        <Box>
                          {!arr[actionEditor.id]?.option?.isprice && !openAddPrice && (
                            <>
                              <Stack direction="column" sx={{ m: 1 }}>
                                <Stack direction="row" spacing={1.5} sx={{ color: '#fff' }}>
                                  <label>
                                    {' '}
                                    Nhập số lượng ô <b>SIM</b> trên 1 ảnh
                                  </label>
                                </Stack>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  placeholder="Nhập số lượng sim trên 1 ảnh"
                                  fullWidth
                                  size="small"
                                  value={amountOfText}
                                  onChange={(e) => {
                                    setAmountOfText(e.target.value);
                                    handleChangeEditText(e.target.value, actionEditor.id);
                                    // console.log(arr[actionEditor.id]);
                                  }}
                                  className={cx('toolbar-input')}
                                  type="number"
                                />
                              </Stack>
                              <Stack direction="row" spacing={1.5} sx={{ m: 1, color: '#fff' }}>
                                <label>
                                  Nhập <b>SIM</b> lần lượt theo thứ tự xuống dòng
                                </label>
                              </Stack>
                              <Stack direction="row" sx={{ m: 1 }}>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  multiline
                                  rows={3}
                                  value={arrMauText && arrMauText[0]?.value.join('\n')}
                                  className={cx('toolbar-input')}
                                  placeholder={`Nhập văn bản...`}
                                  fullWidth
                                  size="small"
                                  onChange={(e) => handleChange(e)}
                                />
                              </Stack>
                            </>
                          )}
                          {arr[actionEditor.id]?.option?.isprice && !openAddPrice && (
                            <>
                              <Stack direction="column" sx={{ m: 1 }}>
                                <Stack direction="row" spacing={1.5} sx={{ color: '#fff' }}>
                                  <label>
                                    {' '}
                                    Nhập số lượng ô <b>GIÁ</b> trên 1 ảnh
                                  </label>
                                </Stack>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  placeholder="Nhập số lượng giá trên 1 ảnh"
                                  fullWidth
                                  size="small"
                                  value={amountOfPrice}
                                  onChange={(e) => {
                                    setAmountOfPrice(e.target.value);
                                    handleChangeEditPrice(e.target.value);
                                  }}
                                  className={cx('toolbar-input')}
                                  type="number"
                                />
                              </Stack>
                              <Stack direction="row" spacing={1.5} sx={{ m: 1, color: '#fff' }}>
                                <label>
                                  Nhập <b>GIÁ</b> lần lượt theo thứ tự xuống dòng (
                                  <i style={{ color: 'gold' }}>Nếu chung 1 GIÁ chỉ cần ghi 1 lần</i>))
                                </label>
                              </Stack>
                              <Stack direction="row" sx={{ m: 1 }}>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  multiline
                                  rows={3}
                                  value={arrMauText && arrMauText[0]?.valuePrice.join('\n')}
                                  className={cx('toolbar-input')}
                                  placeholder={`Nhập văn bản...`}
                                  fullWidth
                                  size="small"
                                  onChange={(e) => handleChangePrice(e)}
                                />
                              </Stack>
                            </>
                          )}
                          {!arr[actionEditor.id]?.option?.isprice &&
                            !openAddPrice &&
                            authToken?.data?.permission === '3' && (
                              <>
                                <Stack direction="column" sx={{ m: 1 }}>
                                  <Stack direction="row" spacing={1.5} sx={{ color: '#fff' }}>
                                    <label>
                                      {' '}
                                      Nhập số lượng ô <b>GIÁ</b> trên 1 ảnh
                                    </label>
                                  </Stack>
                                  <TextField
                                    sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                    placeholder="Nhập số lượng giá trên 1 ảnh"
                                    fullWidth
                                    size="small"
                                    value={amountOfPrice}
                                    onChange={(e) => {
                                      setAmountOfPrice(e.target.value);
                                      handleChangeEditPrice(e.target.value);
                                    }}
                                    className={cx('toolbar-input')}
                                    type="number"
                                  />
                                </Stack>
                                <Stack direction="row" spacing={1.5} sx={{ m: 1, color: '#fff' }}>
                                  <label>
                                    Nhập <b>GIÁ</b> lần lượt theo thứ tự xuống dòng (
                                    <i style={{ color: 'gold' }}>Nếu chung 1 GIÁ chỉ cần ghi 1 lần</i>)
                                  </label>
                                </Stack>
                                <Stack direction="row" sx={{ m: 1 }}>
                                  <TextField
                                    sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                    multiline
                                    rows={3}
                                    value={arrMauText && arrMauText[0]?.valuePrice.join('\n')}
                                    className={cx('toolbar-input')}
                                    placeholder={`Nhập văn bản...`}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => handleChangePrice(e)}
                                  />
                                </Stack>
                              </>
                            )}

                          {openAddPrice && (
                            <>
                              <Stack direction="column" sx={{ m: 1 }}>
                                <Stack direction="row" spacing={1.5} sx={{ color: '#fff' }}>
                                  <label>
                                    {' '}
                                    Nhập số lượng ô <b>GIÁ</b> trên 1 ảnh
                                  </label>
                                </Stack>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  placeholder="Nhập số lượng giá trên 1 ảnh"
                                  fullWidth
                                  size="small"
                                  value={amountOfPrice}
                                  onChange={(e) => {
                                    setAmountOfPrice(e.target.value);
                                    handleChangeEditPrice(e.target.value);
                                  }}
                                  className={cx('toolbar-input')}
                                  type="number"
                                />
                              </Stack>
                              <Stack direction="row" spacing={1.5} sx={{ m: 1, color: '#fff' }}>
                                <label>
                                  Nhập <b>GIÁ</b> lần lượt theo thứ tự xuống dòng (
                                  <i style={{ color: 'gold' }}>Nếu chung 1 GIÁ chỉ cần ghi 1 lần</i>)
                                </label>
                              </Stack>
                              <Stack direction="row" sx={{ m: 1 }}>
                                <TextField
                                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                                  multiline
                                  rows={3}
                                  value={arrMauText && arrMauText[0]?.valuePrice.join('\n')}
                                  className={cx('toolbar-input')}
                                  placeholder={`Nhập văn bản...`}
                                  fullWidth
                                  size="small"
                                  onChange={(e) => handleChangePrice(e)}
                                />
                              </Stack>
                            </>
                          )}

                          <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                            <label style={{ color: '#fff' }}>Chọn Loại Ảnh để phân dạng</label>
                          </Stack>
                          <FormControl sx={{ m: 1, color: '#fff' }} size="small">
                            <InputLabel id="demo-simple-select-helper-label" style={{ color: '#fff' }}>
                              Loại Ảnh
                            </InputLabel>
                            <Select
                              sx={{ color: '#fff', border: '1px solid #fff' }}
                              labelId="demo-simple-select-error-label"
                              id="demo-simple-select-error"
                              value={anetwork}
                              label="Loại Ảnh"
                              onChange={(e) => setAnetwork(e.target.value)}
                              fullWidth
                            >
                              <MenuItem value={'anh-dep'}>Ảnh Đẹp</MenuItem>
                              <MenuItem value={'viettel'}>Ảnh Viettel</MenuItem>
                              <MenuItem value={'vinaphone'}>Ảnh VinaPhone</MenuItem>
                              <MenuItem value={'mobifone'}>Ảnh Mobifone</MenuItem>
                              {(authToken?.data?.permission === '2' || authToken?.data?.permission === '3') &&
                                loaiAnhPhoi?.map((phoi, i) => (
                                  <MenuItem key={i} value={phoi.link}>
                                    {phoi.title}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>

                          {arrMauText && arrMauText[0]?.valuePrice.length === 0 && (
                            <Stack direction={'row'} sx={{ mb: 1 }}>
                              <ButtonUI small color="error" onClick={() => setOpenAddPrice(true)}>
                                THÊM Ô GIÁ
                              </ButtonUI>
                            </Stack>
                          )}
                          <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1 }} alignItems={'center'}>
                            <b className={cx('color-white')}>Chỉnh font của text ({arr[actionEditor.id]?.value}):</b>
                            <FormatBoldIcon
                              className={cx('color-icon-toolbar')}
                              onClick={() =>
                                handleUpdateText(
                                  'bold',
                                  arr[actionEditor.id]?.option?.bold ? '' : 'bold',
                                  actionEditor.id,
                                )
                              }
                              color={arr[actionEditor.id]?.option?.bold ? 'primary' : ''}
                            />
                            <FormatItalicIcon
                              className={cx('color-icon-toolbar')}
                              onClick={() =>
                                handleUpdateText(
                                  'italic',
                                  arr[actionEditor.id]?.option?.italic ? '' : 'italic',
                                  actionEditor.id,
                                )
                              }
                              color={arr[actionEditor.id]?.option?.italic ? 'primary' : ''}
                            />
                            <FormatUnderlinedIcon
                              className={cx('color-icon-toolbar')}
                              onClick={() =>
                                handleUpdateText(
                                  'underline',
                                  arr[actionEditor.id]?.option?.underline ? false : true,
                                  actionEditor.id,
                                )
                              }
                              color={arr[actionEditor.id]?.option?.underline ? 'primary' : ''}
                            />
                            <FontDownloadOutlinedIcon
                              className={cx('color-icon-toolbar')}
                              onClick={() => setActionEditor({ ...actionEditor, toolbar: 2 })}
                            />
                            <span
                              onClick={() => setActionEditor({ ...actionEditor, toolbar: 2 })}
                              className={cx('color-white')}
                            >
                              (Font chữ)
                            </span>
                          </Stack>
                          {messNotifi.isstt === true && (
                            <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1, color: 'red' }}>
                              {messNotifi.notifi}
                            </Stack>
                          )}
                          <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1 }}>
                            <label className={cx('color-icon-toolbar')}>
                              Bạn muốn download nhiều ảnh theo nhiều <b>Text</b> <br /> thì mỗi <b>Text</b> sẽ xuống 1
                              dòng <br />
                              Hệ thống sẽ chạy lần lượt theo <b>Text</b>
                            </label>
                          </Stack>
                        </Box>
                      )}
                      {/* FONT CHỮ */}
                      {actionEditor.toolbar === 2 && (
                        <Box>
                          <Stack direction="column" spacing={0.5} sx={{ mb: 0.5 }}>
                            <b className={cx('color-white')}>Chỉnh font của text ({arr[actionEditor.id]?.value}):</b>
                            {fontCanvas?.map((font, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => {
                                    handleUpdateText('font', font, actionEditor.id);
                                    setActionEditor({ ...actionEditor, toolbar: 1 });
                                  }}
                                >
                                  <label
                                    className={cx('color-icon-toolbar')}
                                    style={{
                                      fontFamily: `${font}`,
                                      fontSize: 18,
                                      color: arr[actionEditor.id]?.option?.font === font ? 'gold' : 'aliceblue',
                                    }}
                                  >
                                    {font}
                                  </label>
                                </div>
                              );
                            })}
                          </Stack>
                        </Box>
                      )}
                      {/* MÀU CHỮ */}
                      {actionEditor.toolbar === 3 && (
                        <Box>
                          <b className={cx('color-white')}>Chỉnh màu của text ({arr[actionEditor.id]?.value}):</b>
                          <Stack direction="row" spacing={2} alignItems="baseline">
                            <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 2 }} alignItems="center">
                              <label className={cx('color-icon-toolbar')}>
                                <b>Màu chữ</b>
                              </label>
                              <input
                                value={arr && arr[actionEditor.id]?.option?.color}
                                type="color"
                                style={{ borderRadius: '5px' }}
                                onChange={(e) => handleColorText(e, actionEditor.id, 'color')}
                              />
                            </Stack>
                            <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                              <label className={cx('color-icon-toolbar')}>
                                <b>Bo viền</b>
                              </label>
                              <Checkbox
                                className={cx('color-icon-toolbar')}
                                color="error"
                                checked={arr && arr[actionEditor.id]?.option?.border}
                                onChange={(e) => handleBorderChecker(e, actionEditor.id, 'border')}
                              />
                            </Stack>
                            {arr && arr[actionEditor.id]?.option?.border && (
                              <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                                <label className={cx('color-icon-toolbar')}>
                                  <b>Màu viền</b>
                                </label>
                                <input
                                  value={arr && arr[actionEditor.id]?.option?.borderColor}
                                  type="color"
                                  style={{ borderRadius: '5px' }}
                                  onChange={(e) => handleColorText(e, actionEditor.id, 'borderColor')}
                                />
                              </Stack>
                            )}
                          </Stack>
                          {arr && arr[actionEditor.id]?.option?.border && (
                            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: 1 }}>
                              <label className={cx('color-icon-toolbar')}>
                                {arr && arr[actionEditor.id]?.option?.strokeTextLine}
                              </label>

                              <Slider
                                max={1000}
                                value={arr && arr[actionEditor.id]?.option?.strokeTextLine}
                                onChange={(e) => updateStyle(e, 'strokeTextLine', actionEditor.id)}
                                valueLabelDisplay="auto"
                                sx={{ color: '#fff' }}
                              />
                              <IconButton
                                onMouseUp={timeoutClearUp}
                                onMouseDown={() => increment('down', 'strokeTextLine', actionEditor.id)}
                                onTouchStart={() => increment('down', 'strokeTextLine', actionEditor.id)}
                                onTouchEnd={timeoutClearUp}
                              >
                                <RemoveCircleOutlineIcon className={cx('color-icon-toolbar')} />
                              </IconButton>
                              <IconButton
                                onMouseUp={timeoutClearUp}
                                onMouseDown={() => increment('up', 'strokeTextLine', actionEditor.id)}
                                onTouchStart={() => increment('up', 'strokeTextLine', actionEditor.id)}
                                onTouchEnd={timeoutClearUp}
                              >
                                <ControlPointIcon className={cx('color-icon-toolbar')} />
                              </IconButton>
                            </Stack>
                          )}

                          <Stack direction="row" spacing={2} sx={{ mb: 0.5 }} alignItems="baseline">
                            <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                              <label className={cx('color-icon-toolbar')}>
                                <b>Khung</b>
                              </label>
                              <Checkbox
                                className={cx('color-icon-toolbar')}
                                color="error"
                                checked={arr && arr[actionEditor.id]?.option?.isFillRect}
                                onChange={(e) => handleBorderChecker(e, actionEditor.id, 'isFillRect')}
                              />
                            </Stack>
                            {arr && arr[actionEditor.id]?.option?.isFillRect && (
                              <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                                <label className={cx('color-icon-toolbar')}>
                                  <b>Màu khung</b>
                                </label>
                                <input
                                  value={arr && arr[actionEditor.id]?.option?.fillRectStyle}
                                  type="color"
                                  style={{ borderRadius: '5px' }}
                                  onChange={(e) => handleColorText(e, actionEditor.id, 'fillRectStyle')}
                                />
                              </Stack>
                            )}
                          </Stack>
                        </Box>
                      )}
                      {/* font size */}
                      {actionEditor.toolbar === 4 && (
                        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: 1 }}>
                          <label className={cx('color-icon-toolbar')}>
                            {arr && arr[actionEditor.id]?.option?.size}
                          </label>

                          <Slider
                            max={1000}
                            value={arr && arr[actionEditor.id]?.option?.size}
                            onChange={(e) => updateStyle(e, 'size', actionEditor.id)}
                            valueLabelDisplay="auto"
                            sx={{ color: '#fff' }}
                          />
                          <IconButton
                            onMouseUp={timeoutClearUp}
                            onMouseDown={() => increment('down', 'size', actionEditor.id)}
                            onTouchStart={() => increment('down', 'size', actionEditor.id)}
                            onTouchEnd={timeoutClearUp}
                          >
                            <RemoveCircleOutlineIcon className={cx('color-icon-toolbar')} />
                          </IconButton>
                          <IconButton
                            onMouseUp={timeoutClearUp}
                            onMouseDown={() => increment('up', 'size', actionEditor.id)}
                            onTouchStart={() => increment('up', 'size', actionEditor.id)}
                            onTouchEnd={timeoutClearUp}
                          >
                            <ControlPointIcon className={cx('color-icon-toolbar')} />
                          </IconButton>
                        </Stack>
                      )}
                      {/* font size */}
                      {actionEditor.toolbar === 5 && (
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 1 }}>
                          <label className={cx('color-icon-toolbar')}>
                            {arr && arr[actionEditor.id]?.option?.rotate}
                          </label>

                          <Slider
                            max={360}
                            value={arr && arr[actionEditor.id]?.option?.rotate}
                            onChange={(e) => updateStyle(e, 'rotate', actionEditor.id)}
                            valueLabelDisplay="auto"
                            sx={{ color: '#fff' }}
                          />
                          <IconButton
                            onMouseUp={timeoutClearUp}
                            onMouseDown={() => increment('down', 'rotate', actionEditor.id)}
                            onTouchStart={() => increment('down', 'rotate', actionEditor.id)}
                            onTouchEnd={timeoutClearUp}
                          >
                            <RemoveCircleOutlineIcon className={cx('color-icon-toolbar')} />
                          </IconButton>
                          <IconButton
                            onMouseUp={timeoutClearUp}
                            onMouseDown={() => increment('up', 'rotate', actionEditor.id)}
                            onTouchStart={() => increment('up', 'rotate', actionEditor.id)}
                            onTouchEnd={timeoutClearUp}
                          >
                            <ControlPointIcon className={cx('color-icon-toolbar')} />
                          </IconButton>
                        </Stack>
                      )}
                      {/* lên xuống */}
                      {actionEditor.toolbar === 6 && (
                        <Box>
                          {/* <Stack spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                      <Box className={cx('rotateMode')}>
                        <IconButton
                          className={cx('left')}
                          onClick={() => handleMoveStyle('left', 'left', actionEditor.id)}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                          className={cx('right')}
                          onClick={() => handleMoveStyle('right', 'left', actionEditor.id)}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                        <IconButton className={cx('up')} onClick={(e) => handleMoveStyle('up', 'top', actionEditor.id)}>
                          <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                          className={cx('down')}
                          onClick={() => handleMoveStyle('down', 'top', actionEditor.id)}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </Box>
                    </Stack> */}
                          {/* sang trái sang phải */}
                          <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: 1 }}>
                            <label className={cx('color-icon-toolbar')}>
                              {arr && arr[actionEditor.id]?.option?.left}
                            </label>
                            <IconButton>
                              <SyncAltIcon className={cx('color-icon-toolbar')} />
                            </IconButton>

                            <Slider
                              max={2000}
                              value={arr && arr[actionEditor.id]?.option?.left}
                              onChange={(e) => updateStyle(e, 'left', actionEditor.id)}
                              valueLabelDisplay="auto"
                              sx={{ color: '#fff' }}
                            />
                            <IconButton
                              onMouseUp={timeoutClearUp}
                              onMouseDown={() => increment('down', 'left', actionEditor.id)}
                              onTouchStart={() => increment('down', 'left', actionEditor.id)}
                              onTouchEnd={timeoutClearUp}
                            >
                              <RemoveCircleOutlineIcon className={cx('color-icon-toolbar')} />
                            </IconButton>
                            <IconButton
                              onMouseUp={timeoutClearUp}
                              onMouseDown={() => increment('up', 'left', actionEditor.id)}
                              onTouchStart={() => increment('up', 'left', actionEditor.id)}
                              onTouchEnd={timeoutClearUp}
                            >
                              <ControlPointIcon className={cx('color-icon-toolbar')} />
                            </IconButton>
                          </Stack>

                          {/* từ trên xuống dưới */}
                          <Stack direction="row" spacing={0.5} alignItems="center">
                            <label className={cx('color-icon-toolbar')}>
                              {arr && arr[actionEditor.id]?.option?.top}
                            </label>
                            <IconButton onClick={() => setActionEditor({ ...actionEditor, action: 'toolbar' })}>
                              <SwapVertIcon className={cx('color-icon-toolbar')} />
                            </IconButton>

                            <Slider
                              max={2000}
                              value={arr && arr[actionEditor.id]?.option?.top}
                              onChange={(e) => updateStyle(e, 'top', actionEditor.id)}
                              valueLabelDisplay="auto"
                              sx={{ color: '#fff' }}
                            />
                            <IconButton
                              onMouseUp={timeoutClearUp}
                              onMouseDown={() => increment('down', 'top', actionEditor.id)}
                              onTouchStart={() => increment('down', 'top', actionEditor.id)}
                              onTouchEnd={timeoutClearUp}
                            >
                              <RemoveCircleOutlineIcon className={cx('color-icon-toolbar')} />
                            </IconButton>
                            <IconButton
                              onMouseUp={timeoutClearUp}
                              onMouseDown={() => increment('up', 'top', actionEditor.id)}
                              onTouchStart={() => increment('up', 'top', actionEditor.id)}
                              onTouchEnd={timeoutClearUp}
                            >
                              <ControlPointIcon className={cx('color-icon-toolbar')} />
                            </IconButton>
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  </div>
                )}
              </Grid>
              <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                    <label>
                      Nhập số lượng ô <b>SIM</b> trên 1 ảnh
                    </label>
                  </Stack>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                    placeholder="Nhập số lượng sim trên 1 ảnh"
                    fullWidth
                    size="small"
                    value={amountOfText}
                    onChange={(e) => {
                      setAmountOfText(e.target.value);
                      parseInt(e.target.value) > text?.value.length
                        ? setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng Ô SIM' })
                        : setMessNotifi({ isstt: false, notifi: '' });
                    }}
                    type="number"
                  />
                  {amountOfText !== '' && (
                    <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                      <label>
                        Nhập <b>SIM</b> lần lượt theo thứ tự xuống dòng
                      </label>
                    </Stack>
                  )}
                  {amountOfText !== '' && (
                    <TextField
                      sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                      multiline
                      rows={4}
                      placeholder="Nhập sim theo thứ tự xuống dòng"
                      fullWidth
                      size="small"
                      value={Array.isArray(text?.value) ? text?.value.join('\n') : ''}
                      onChange={(e) => {
                        setText({ ...inputArr, value: e.target.value.split('\n') });
                        e.target.value.split('\n').length < parseInt(amountOfText)
                          ? setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng Ô SIM' })
                          : setMessNotifi({ isstt: false, notifi: '' });
                      }}
                    />
                  )}
                  <label style={{ display: 'flex', justifyContent: 'center' }}>
                    ----------------- NHẬP Ô GIÁ NẾU CẦN------------
                  </label>
                  <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                    <label>
                      Nhập số lượng ô <b>GIÁ</b> trên 1 ảnh
                    </label>
                  </Stack>
                  <TextField
                    sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                    placeholder="Nhập số lượng ô giá trên 1 ảnh"
                    fullWidth
                    size="small"
                    value={amountOfPrice}
                    onChange={(e) => {
                      setAmountOfPrice(e.target.value);
                      parseInt(e.target.value) > text?.valuePrice.length
                        ? setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng Ô GIÁ ' })
                        : setMessNotifi({ isstt: false, notifi: '' });
                    }}
                    type="number"
                  />

                  {amountOfPrice !== '' && (
                    <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                      <label>
                        Nhập <b>GIÁ</b> lần lượt theo thứ tự xuống dòng (
                        <i style={{ color: 'red' }}>Nếu chung 1 GIÁ chỉ cần ghi 1 lần</i>)
                      </label>
                    </Stack>
                  )}
                  {amountOfPrice !== '' && (
                    <TextField
                      sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                      multiline
                      rows={4}
                      placeholder="Nhập giá theo thứ tự xuống dòng"
                      fullWidth
                      size="small"
                      value={Array.isArray(text?.valuePrice) ? text?.valuePrice.join('\n') : ''}
                      onChange={(e) => {
                        setText({ ...text, valuePrice: e.target.value.split('\n') });
                        e.target.value.split('\n').length < parseInt(amountOfPrice)
                          ? setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng Ô SIM' })
                          : setMessNotifi({ isstt: false, notifi: '' });
                      }}
                    />
                  )}
                  <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                    <label>Chọn Loại Ảnh để phân dạng</label>
                  </Stack>
                  <FormControl sx={{ m: 1 }} size="small">
                    <InputLabel id="demo-simple-select-helper-label">Loại Ảnh</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={anetwork}
                      label="Loại Ảnh"
                      onChange={(e) => setAnetwork(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={'anh-dep'}>Ảnh Đẹp</MenuItem>
                      <MenuItem value={'viettel'}>Ảnh Viettel</MenuItem>
                      <MenuItem value={'vinaphone'}>Ảnh VinaPhone</MenuItem>
                      <MenuItem value={'mobifone'}>Ảnh Mobifone</MenuItem>
                      {(authToken?.data?.permission === '2' || authToken?.data?.permission === '3') &&
                        loaiAnhPhoi?.map((phoi, i) => (
                          <MenuItem key={i} value={phoi.link}>
                            {phoi.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ mb: 0.5, ml: 1 }}>
                    <div className={cx('box-list-subcate')}>
                      <b className={cx('color-red')}>Chọn font sim:</b>
                      <div className={cx('list-subcate')}>
                        <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
                          {fontCanvas?.map((font, i) => {
                            return (
                              <div
                                key={i}
                                onClick={() => {
                                  handleFontText('font', font);
                                }}
                              >
                                <label
                                  style={{
                                    fontFamily: `${font}`,
                                    fontSize: 18,
                                    color: arrOption?.option?.font === font ? 'gold' : 'black',
                                    borderRight: '1px solid #ccc',
                                    paddingRight: '5px',
                                  }}
                                >
                                  {font}
                                </label>
                              </div>
                            );
                          })}
                        </Stack>
                      </div>
                    </div>
                  </Box>

                  <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1 }} alignItems={'center'}>
                    <b className={cx('color-red')}>Chỉnh kiểu text:</b>
                    <FormatBoldIcon
                      className={cx('color-icon-toolbar')}
                      onClick={() => handleFontText('bold', arrOption?.option?.bold ? '' : 'bold')}
                      color={arrOption?.option?.bold ? 'primary' : 'secondary'}
                    />
                    <FormatItalicIcon
                      className={cx('color-icon-toolbar')}
                      onClick={() => handleFontText('italic', arrOption?.option?.italic ? '' : 'italic')}
                      color={arrOption?.option?.italic ? 'primary' : 'secondary'}
                    />
                    <FormatUnderlinedIcon
                      className={cx('color-icon-toolbar')}
                      onClick={() => handleFontText('underline', arrOption?.option?.underline ? false : true)}
                      color={arrOption?.option?.underline ? 'primary' : 'secondary'}
                    />
                  </Stack>

                  <Box sx={{ mb: 0.5, ml: 1 }}>
                    <b className={cx('color-red')}>Chỉnh size chữ:</b>
                    <Stack direction="row" spacing={0.5} alignItems="center" sx={{ ml: 1 }}>
                      <label>{arrOption?.option?.size}</label>

                      <Slider
                        max={1000}
                        value={arrOption?.option?.size}
                        onChange={(e) => handleFontText('size', e.target.value)}
                        valueLabelDisplay="auto"
                      />
                      <IconButton
                        onMouseUp={timeoutClearUp}
                        onMouseDown={(e) => handleFontText('size', arrOption?.option?.size - 1)}
                        onTouchStart={(e) => handleFontText('size', arrOption?.option?.size - 1)}
                        onTouchEnd={timeoutClearUp}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <IconButton
                        onMouseUp={timeoutClearUp}
                        onMouseDown={(e) => handleFontText('size', arrOption?.option?.size + 1)}
                        onTouchStart={(e) => handleFontText('size', arrOption?.option?.size + 1)}
                        onTouchEnd={timeoutClearUp}
                      >
                        <ControlPointIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                  <Box sx={{ mb: 0.5, ml: 1 }}>
                    <b className={cx('color-red')}>Chỉnh màu của text:</b>
                    <Stack direction="row" spacing={2} alignItems="baseline">
                      <Stack direction="row" spacing={0.5} sx={{ mb: 0.5, mt: 2 }} alignItems="center">
                        <label>
                          <b>Màu chữ</b>
                        </label>
                        <input
                          value={arrOption?.option?.color}
                          type="color"
                          style={{ borderRadius: '5px' }}
                          onChange={(e) => handleFontText('color', e.target.value)}
                        />
                      </Stack>
                      <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                        <label>
                          <b>Bo viền</b>
                        </label>
                        <Checkbox
                          color="error"
                          checked={arrOption?.option?.border}
                          onChange={(e) => handleFontText('border', e.target.checked)}
                        />
                      </Stack>
                      {arrOption?.option?.border && (
                        <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                          <label>
                            <b>Màu viền</b>
                          </label>
                          <input
                            value={arrOption?.option?.borderColor}
                            type="color"
                            style={{ borderRadius: '5px' }}
                            onChange={(e) => handleFontText('borderColor', e.target.value)}
                          />
                        </Stack>
                      )}
                    </Stack>
                    {arrOption?.option?.border && <b className={cx('color-red')}>Kích thước viền:</b>}
                    {arrOption?.option?.border && (
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <label>{arrOption?.option?.strokeTextLine}</label>

                        <Slider
                          max={1000}
                          value={arr && arr[actionEditor.id]?.option?.strokeTextLine}
                          onChange={(e) => handleFontText('strokeTextLine', e.target.value)}
                          valueLabelDisplay="auto"
                        />
                        <IconButton
                          onMouseUp={timeoutClearUp}
                          onMouseDown={(e) => handleFontText('strokeTextLine', arrOption?.option?.strokeTextLine - 1)}
                          onTouchStart={(e) => handleFontText('strokeTextLine', arrOption?.option?.strokeTextLine - 1)}
                          onTouchEnd={timeoutClearUp}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                        <IconButton
                          onMouseUp={timeoutClearUp}
                          onMouseDown={(e) => handleFontText('strokeTextLine', arrOption?.option?.strokeTextLine + 1)}
                          onTouchStart={(e) => handleFontText('strokeTextLine', arrOption?.option?.strokeTextLine + 1)}
                          onTouchEnd={timeoutClearUp}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </Stack>
                    )}

                    <Stack direction="row" spacing={2} sx={{ mb: 0.5 }} alignItems="baseline">
                      <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                        <label>
                          <b>Khung</b>
                        </label>
                        <Checkbox
                          color="error"
                          checked={arrOption?.option?.isFillRect}
                          onChange={(e) => handleFontText('isFillRect', e.target.checked)}
                        />
                      </Stack>
                      {arrOption?.option?.isFillRect && (
                        <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }} alignItems="center">
                          <label>
                            <b>Màu khung</b>
                          </label>
                          <input
                            value={arrOption?.option?.fillRectStyle}
                            type="color"
                            style={{ borderRadius: '5px' }}
                            onChange={(e) => handleFontText('fillRectStyle', e.target.value)}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                  {messNotifi.isstt === true && (
                    <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1, color: 'red' }}>
                      {messNotifi.notifi}
                    </Stack>
                  )}
                  <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1 }}>
                    <label>
                      Bạn muốn download nhiều ảnh theo nhiều <b>Text</b> <br /> thì mỗi <b>Text</b> sẽ xuống 1 dòng.{' '}
                      <br />
                      Hệ thống sẽ chạy lần lượt theo <b>Text</b>
                    </label>
                  </Stack>
                  {!messNotifi.isstt && (
                    <ButtonUI
                      startIcon={<FormatSizeIcon />}
                      color="error"
                      onClick={() => {
                        addInput();
                        setOpenSpeedDial(false);
                        handleClose();
                        onCloseAddText();
                      }}
                      disabled={messNotifi.isstt}
                    >
                      Add Text
                    </ButtonUI>
                  )}
                </Box>
              </Modal>
            </Grid>
          </Container>
        )}
        {layout === 1 && (
          <Container
            ref={prevScrollY}
            // maxWidth="sm"
            // sx={{
            //   pb: '55px',
            //   mt: '55px',
            // }}
          >
            <Grid container sx={{ mt: 2 }}>
              {messNotifi.isstt === true && (
                <Stack direction="row" justifyContent={'center'} spacing={1.5} sx={{ color: 'red' }}>
                  {messNotifi.notifi}
                </Stack>
              )}
              {authToken?.data?.permission === '2' ||
              authToken?.data?.permission === '3' ||
              authToken?.data?.permission === '4' ? (
                <ButtonUI
                  startIcon={<FormatSizeIcon />}
                  sx={{ mb: 2 }}
                  color="error"
                  onClick={() => {
                    setLayout(2);
                    resetArr();
                  }}
                  disabled={messNotifi.isstt}
                >
                  Tạo Ảnh Mới
                </ButtonUI>
              ) : (
                <ButtonUI startIcon={<FormatSizeIcon />} sx={{ mb: 2 }} color="error" disabled={true}>
                  Tạo Ảnh Mới
                </ButtonUI>
              )}
              <div className={cx('box-list-subcate')}>
                <b>
                  Số Lượng Sim trên 1 ảnh: {authToken?.data?.permission === '1' ? 'Nâng cấp gói vip để mở full' : ''}
                </b>
                <div className={cx('list-subcate')}>
                  <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
                    {authToken?.data?.permission === '1' &&
                      soluongTextPhoi.map((slp, i) => (
                        <Button
                          key={i}
                          variant={amountOfText === slp ? 'contained' : 'outlined'}
                          size="small"
                          color="error"
                          onClick={() => {
                            handleIsInFoImagePhoi(slp, anetwork);
                            setAmountOfText(slp);
                          }}
                          disabled={(authToken?.data?.permission === '1' && i < 2 ? false : true) || loading}
                        >
                          {slp}
                        </Button>
                      ))}
                    {authToken?.data?.permission !== '1' &&
                      soluongTextPhoi.map((slp, i) => (
                        <Button
                          key={i}
                          variant={amountOfText === slp ? 'contained' : 'outlined'}
                          size="small"
                          color="error"
                          onClick={() => {
                            handleIsInFoImagePhoi(slp, anetwork);
                            setAmountOfText(slp);
                          }}
                          disabled={loading}
                        >
                          {slp}
                        </Button>
                      ))}
                  </Stack>
                </div>
              </div>

              <div className={cx('box-list-subcate')}>
                <b>Loại ảnh:</b>
                <div className={cx('list-subcate')}>
                  <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
                    {(authToken?.data?.permission === '2' || authToken?.data?.permission === '3') &&
                      loaiAnhPhoi?.map((phoi, i) => (
                        <Button
                          key={i}
                          variant={anetwork === phoi.link ? 'contained' : 'outlined'}
                          size="small"
                          color="success"
                          onClick={() => {
                            handleIsInFoImagePhoi(amountOfText, phoi.link);
                            setAnetwork(phoi.link);
                          }}
                          disabled={loading}
                        >
                          {phoi.title}
                        </Button>
                      ))}
                    {loaiAnh.map((net, i) => (
                      <Button
                        key={i}
                        variant={anetwork === net.link ? 'contained' : 'outlined'}
                        size="small"
                        color="success"
                        onClick={() => {
                          handleIsInFoImagePhoi(amountOfText, net.link);
                          setAnetwork(net.link);
                        }}
                        disabled={loading}
                      >
                        {net.title}
                      </Button>
                    ))}
                  </Stack>
                </div>
              </div>
              {loading && (
                <Box sx={{ width: '100%', mt: 1 }}>
                  <LinearProgress />
                </Box>
              )}

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Stack direction={'row'}>
                  <b>Tổng ảnh: {totalRows} ảnh</b>
                </Stack>
                {/* <Masonry columns={{ xs: 2, sm: 2, md: 4 }} spacing={1}>
                  {arrCanvasImage.map((item, index) => (
                    <div key={index}>
                      <Stack
                        direction={'row'}
                        className={cx('css-image-boder')}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <b>DS_{index + 1}</b>
                        {getLocalStorage('sv') === '1' && (
                          <ButtonUI small color="error" onClick={() => removeImageSample(item.name)}>
                            Xóa
                          </ButtonUI>
                        )}
                      </Stack>
                      <img
                        src={`${item.imageblo}`}
                        alt={index}
                        loading="lazy"
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: 'block',
                          width: '100%',
                        }}
                        onClick={() => {
                          setLayout(3);
                          handleDrawerPhotoDetail(item);
                        }}
                      />
                    </div>
                  ))}
                </Masonry> */}

                <ImageList variant="quilted" cols={isMobile ? 5 : 2} gap={6}>
                  {arrCanvasImage.map((item, index) => (
                    <ImageListItem key={item.name}>
                      <img
                        src={`${item.imageblo}`}
                        alt={item.name}
                        loading="lazy"
                        onClick={() => {
                          setLayout(3);
                          handleDrawerPhotoDetail(item);
                        }}
                        style={{ height: 'auto' }}
                      />
                      <ImageListItemBar
                        position="bottom"
                        title={`DS_${index + 1}`}
                        actionIcon={
                          getLocalStorage('sv') === '1' && (
                            <ButtonUI small color="error" onClick={() => removeImageSample(item.name)}>
                              Xóa
                            </ButtonUI>
                          )
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                {Math.ceil(totalRows / 5) > page && !loading && (
                  <Button
                    color="success"
                    variant="contained"
                    onClick={xemthemImage}
                    sx={{ mt: 1 }}
                    disabled={authToken?.data?.permission === '1'}
                  >
                    {authToken?.data?.permission !== '1' ? `XEM THÊM ẢNH ( ${totalRows - page * 5} ảnh còn)` : ''}

                    <b style={{ color: 'red' }}>{authToken?.data?.permission === '1' ? 'Nâng cấp để xem thêm' : ''}</b>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        )}
        {layout === 3 && (
          <Container
            ref={prevScrollY}
            // maxWidth="sm"
          >
            <ButtonUI
              startIcon={<ReplyIcon />}
              color="success"
              onClick={() => {
                setLayout(1);
              }}
              sx={{ mb: 2, mt: 2 }}
            >
              Quay lại thư viện
            </ButtonUI>
            <Grid container>
              <Box sx={styleDetail}>
                <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                  <b className={cx('color-blue')}>Số lượng SIM trên 1 ảnh là: {amountOfText}</b>
                </Stack>
                <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                  <label>
                    Nhập <b>SIM</b> lần lượt theo thứ tự xuống dòng
                  </label>
                </Stack>
                <TextField
                  sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                  multiline
                  rows={4}
                  placeholder="Nhập sim theo thứ tự xuống dòng"
                  fullWidth
                  size="small"
                  value={Array.isArray(arrTextVBs?.value) ? arrTextVBs?.value.join('\n') : ''}
                  onChange={(e) => {
                    setArrTextVBs({ ...inputArr, value: e.target.value.split('\n') });
                    e.target.value.split('\n').length < parseInt(amountOfText)
                      ? setMessNotifi({ isstt: true, notifi: 'Ô số lượng phải nhỏ hơn hoặc bằng số lượng Ô SIM' })
                      : setMessNotifi({ isstt: false, notifi: '' });
                  }}
                />

                {arrMauText[0]['valuePrice'].length > 0 && (
                  <>
                    <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                      <b className={cx('color-red')}>Số lượng GIÁ trên 1 ảnh là: {amountOfPrice}</b>
                    </Stack>
                    <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                      <label>
                        Nhập <b>GIÁ</b> lần lượt theo thứ tự xuống dòng
                      </label>
                    </Stack>
                    <TextField
                      sx={{ border: '1px solid #fff', borderRadius: '5px' }}
                      multiline
                      rows={4}
                      placeholder="Nhập giá theo thứ tự xuống dòng"
                      fullWidth
                      size="small"
                      value={Array.isArray(arrTextVBs?.valuePrice) ? arrTextVBs?.valuePrice.join('\n') : ''}
                      onChange={(e) => {
                        setArrTextVBs({ ...arrTextVBs, valuePrice: e.target.value.split('\n') });
                      }}
                    />
                  </>
                )}
                <Stack
                  direction="row"
                  spacing={1.5}
                  sx={{ mb: 0.5, ml: 1, mt: 1 }}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <ButtonUI
                    startIcon={<FormatSizeIcon />}
                    color="error"
                    onClick={() => {
                      canvasDetail(imageSample, arrTextVBs);
                    }}
                    sx={{ mt: 2 }}
                    disabled={loading}
                  >
                    TẠO ẢNH
                  </ButtonUI>
                  Số lượng ảnh: {Math.ceil(parseInt(arrTextVBs?.value?.length) / parseInt(amountOfText)) || 0}
                </Stack>
              </Box>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                {loading && (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
                <Stack direction="row" spacing={1.5} sx={{ mb: 0.5, ml: 1, mt: 1 }}>
                  {authToken?.data?.permission === '1' && arrCanvasImageDetail?.length > 2 && (
                    <h2 style={{ color: 'green' }}>Hiển thị xem tối đa 2 ảnh (Nâng cấp để hiển thị thêm)</h2>
                  )}
                  {authToken?.data?.permission !== '1' && arrCanvasImageDetail?.length > 15 && (
                    <h2 style={{ color: 'green' }}>Hiển thị xem tối đa 15 ảnh</h2>
                  )}
                  {Math.ceil(parseInt(arrTextVBs?.value?.length) / parseInt(amountOfText)) > 2 &&
                    (authToken?.data?.permission === '2' ||
                      authToken?.data?.permission === '3' ||
                      authToken?.data?.permission === '4') && (
                      <ButtonUI startIcon={<CloudDownloadIcon />} color="secondary" onClick={downloadAllImageLayout3}>
                        Download tất cả {Math.ceil(parseInt(arrTextVBs?.value?.length) / parseInt(amountOfText)) || 0}{' '}
                        Ảnh
                      </ButtonUI>
                    )}
                  {Math.ceil(parseInt(arrTextVBs?.value?.length) / parseInt(amountOfText)) > 2 &&
                    authToken?.data?.permission === '1' && (
                      <ButtonUI startIcon={<CloudDownloadIcon />} disabled>
                        Download tất cả {Math.ceil(parseInt(arrTextVBs?.value?.length) / parseInt(amountOfText)) || 0}{' '}
                        Ảnh (Nâng cấp gói VIP để sử dụng)
                      </ButtonUI>
                    )}
                </Stack>

                <ImageList
                  variant="masonry"
                  sx={{
                    columnCount: {
                      xs: '2 !important',
                      sm: '2 !important',
                      md: '4 !important',
                      lg: '4 !important',
                      xl: '4 !important',
                    },
                  }}
                  gap={8}
                >
                  {arrCanvasImageDetail?.map((item, i) => (
                    <ImageListItem key={i}>
                      <img src={item} alt={i} loading="lazy" />

                      <ImageListItemBar
                        position="bottom"
                        title={`DS_${i + 1}`}
                        actionIcon={
                          <ButtonUI
                            startIcon={<CloudDownloadIcon />}
                            small
                            color="info"
                            onClick={() => downloadImageLayout3(item)}
                          >
                            Tải xuống
                          </ButtonUI>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
};

export default EditorImage;
