import * as reqAuth from '../utils/requestAuthServer';
const hostname = window.location.hostname;
export const getAllImageSample = async (amount, network, page = 1) => {
  try {
    const res = await reqAuth.getSim(`/getallImage?amount=${amount}&cate=${network}&page=${page}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAllImageSampleCount = async (amount, network) => {
  try {
    const res = await reqAuth.getSim(`/getallImageCount?amount=${amount}&cate=${network}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageSample = async (formData) => {
  try {
    const res = await reqAuth.postUpload(`/addimagesample`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const addImageSamples = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addimagesamples`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateImageSample = async (formData) => {
  try {
    const res = await reqAuth.postUpload(`/updateimagesample`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeImageSamples = async (data) => {
  try {
    const res = await reqAuth.postSim(`/removeimagesample`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const refreshToken = async (data) => {
  try {
    const res = await reqAuth.postSim(`/refresh`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
