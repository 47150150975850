import config from '../config';
import LoginLayout from '../layouts/LoginLayout';
import { Login, NotifiSim, EditTableExcel, EditorImage, EditTableExcels } from '../pages';

//component pages

const publicRoutes = [
  { path: config.routes.home, component: EditorImage },
  { path: config.routes.tableexcel, component: EditTableExcel },
  { path: config.routes.tableexcels, component: EditTableExcels },
  { path: config.routes.photo, component: EditorImage },
  { path: config.routes.login, component: Login, layout: LoginLayout },
  { path: config.routes.success, component: NotifiSim, layout: LoginLayout },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
